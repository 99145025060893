import { combineReducers } from 'redux';

import roomReducer from './room_reducer';
import roomRateReducer from './room_rate_reducer';
import bookReducer from './book_reducer';
import paymentReducer from './payment_reducer';
import loginReducer from './login_reducer';
import bookingDetailReducer from './booking_detail_reducer';
import contentReducer from './content_reducer';
import cancelBookingReducer from './cancel_booking_reducer';
import modifyBookingReducer from './modify_booking_reducer';

const rootReducer = combineReducers({
  rooms: roomReducer,
  roomRate: roomRateReducer,
  book: bookReducer,
  payment: paymentReducer,
  login: loginReducer,
  bookingDetail: bookingDetailReducer,
  content: contentReducer,
  cancelBooking: cancelBookingReducer,
  modifyBooking: modifyBookingReducer
});

export default rootReducer;
