import React from 'react';

const styles = {
    subTitle:{
        textAlign:'left',
        margin:'15px 0px 0px 20px',
        fontWeight:'bold'
    },
    privacyContentFirstTitle:{
        fontWeight:'bold',
    },
    privacyContentRestTitle:{
        fontWeight:'normal',
    },
		policyText:{
				marginLeft: '20px'
		}
}

const generalInformationContent = () => (
		<ol style={{textAlign:'left',padding:'10px 20px 0px 20px'}}>
				<li>{/*1*/}
						In compliance with the Personal Data Protection Commission Singapore (PDPC)’s advisory guidelines on NRIC numbers issued on 31 August 2018, with effect from 1 September 2019, D’Resort will no longer accept check-ins on behalf of someone else. Registered guests are required to check-in personally for their stay.
				</li>
				<li>{/*2*/}
						Guests may not move furnishings or interfere with the electrical network or any other installations in the rooms or on the premises of D’Resort without the consent of the Management. Any damage to the room and the resort's fixtures, fittings and furnishings will be the responsibility of the guests and will be charged accordingly. A minimum $100 cleaning fee will be imposed for excessively messy or dirty rooms.
				</li>
				<li>{/*3*/}
						Smoking is only permitted at designated areas in D'Resort. All other premises in D'Resort have a non-smoking policy and $200 will be charged to your room for guests in violation of this.
				</li>
				<li>{/*4*/}
						In the event of false fire alarm activated by guests, a charge of $500 will be imposed.
				</li>
				<li>{/*5*/}
						Catering of food is only allowed through our appointed caterer. No other caterers are allowed in the resort premises. Catering venue will be advised accordingly.
				</li>
				<li>{/*6*/}
					For safety reasons, the charging of Portable Mobility Devices (PMDs) within D'Resort is not allowed.
				</li>
				<li>{/*7*/}
						No decorations or fixtures from external vendors are allowed in the room. Please email <a href="mailto:decorations@ntucclub.com.sg">decorations@ntucclub.com.sg</a> for more information on the appointed vendors. We reserved the rights to ask guest to remove any unauthorised decorations or fixtures. Should there be any decorations that damage the fittings, charges will apply.
				</li>
				<li>{/*8*/}
						Maximum guests permitted to stay in the room are based on room type and bedding configuration. For your safety, please abide strictly with the maximum room occupancy due to the Fire & Safety Code as stipulated by the SCDF Fire Code Review Committee and the FSSD Standing Committee. All visitors are to leave the resort premises after 11pm and the management reserves all rights to escort out any additional persons not complying with the Terms & Conditions.
				</li>
        <li>{/*9*/}
            Please be informed that the Goods and Services Tax (GST) will increase from 8% to 9% from 1 January 2024 onwards. The GST rate at 9% will be imposed on payments and transactions made on or after 1 January 2024.
        </li>
		</ol>
);

export default class TermsConditions extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        let policyCancellationDate = '';
        let ratePlanName = '';
        let ratePolicy = '';

        return <div className="modal fade popUpModel" id="booking-tc-information" tabIndex="-1" role="dialog" aria-hidden="true">
            <div style={{maxWidth:1000}} className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="modal-header">
                            <h4 className="modal-title">Need To Know</h4>
                            <button type="button" className="close" data-dismiss="modal">&#10005;</button>
                        </div>
                        <div style={styles.subTitle}>
                            Check-In
                        </div>
                        <ul>
                            <li>Check-in after 3pm.</li>
                            <li>Minimum check-in age: 18 years old.</li>
                            <li>Guests are required to present physical government-issued photo identification and credit card used for booking upon check-in for verification. Digital identification cards (Singpass) are not accepted.</li>
                            <li>Members are required to produce their physical NTUC Union/Link members card upon check-in for verification purposes and Linkpoints issuance. We are unable to issue linkpoints without the physical card.</li>
                        </ul>

                        <div style={styles.subTitle}>
                            Check-Out
                        </div>
                        <ul>
                            <li>Check-out before 11am.</li>
                        </ul>

                        <div style={styles.subTitle}>
                            Extra Bed & Baby Cot
                        </div>
                        <ul>
                            <li>Children 0 - 12 years occupying the same room with at least one adult and using existing bedding will not incur additional charges. Additional charges may apply if extra bed or linen are required.</li>
                            <li>Baby Cot is free.</li>
                        </ul>

                        <div style={styles.subTitle}>
                            Pets
                        </div>
                        <ul>
                            <li>Pets are not allowed.</li>
                        </ul>

                        <div style={styles.subTitle}>
                            Ground Floor / Connecting Room
                        </div>
                        <ul>
                            <li>Requests for ground floor and/or connecting rooms are subject to availability and surcharge of $30 (excl. GST) per room per night will apply. Confirmation of ground floor rooms will only be available upon arrival. Room numbers will be assigned upon check-in.</li>
                        </ul>

                        <div style={styles.subTitle}>
                            Parking
                        </div>
                        <ul>
                            <li>Parking charges apply. Parking lot availability is on a first come first served basis.</li>
                            <li>Parking lots are exclusively for D’Resort guests, multiple entry concession pass can be purchased at iCounter located at level 2 lobby (guests are required to provide vehicle IU number). Concession pass will be charged at S$10 per night for members and S$12 per night for non-members; valid till following day 7.00pm and limited to one concession pass per room. For visiting guests, please visit <a target="_blank" href="https://www.dresort.com.sg/destination/getting-to-d'resort">https://www.dresort.com.sg/destination/getting-to-d'resort</a> for parking charges.</li>
                        </ul>

                        <div style={styles.subTitle}>
                            Catering Of Food
                        </div>
                        <ul>
                            <li>Catering of food is only allowed through our appointed caterer. Please visit <a target="_blank" href="https://www.dresort.com.sg/explore/eat">https://www.dresort.com.sg/explore/eat</a> for more details. No other caterers are allowed in the resort premises.</li>
                        </ul>

                        <div className="modal-header">
                            <h4 className="modal-title">Important Information</h4>
                        </div>
                        {/*<div style={styles.subTitle}>
                            Important Information
                        </div>*/}
                        {generalInformationContent()}
                        {/*<div style={styles.subTitle}>
                            Privacy Policy
                        </div>
                        <div style={{textAlign:'left',margin:'15px 0px 0px 20px'}}>
                            By accepting the term and condition, I have also read and accepted to the NTUC Club’s Privacy Policies http://www.ntucclub.com/privacy-policy/ which is incorporated into this Agreement by reference.
                        </div>
                        <br/>*/}

                        {/* <div className="modal-header">
                            <h4 className="modal-title">Cancellation Policy</h4>
                        </div>
                        <div className="cancellation-policy">
                            <div id="cancellation-policy">
                                <div style={styles.subTitle}>
                                    Booking under { ratePlanName }
                                </div>
                                <div style={styles.policyText}>{ ratePolicy.name }</div>
                                { ratePolicy.is_non_refundable ?
                                    null :
                                    <div style={styles.policyText}>
                                        Before { policyCancellationDate } (S$ { ratePolicy.penalty } admin fee per room applies)
                                    </div>
                                }
                                { ratePolicy.description != '' ? <div style={styles.policyText}>{ ratePolicy.description }</div> : null }
                            </div>
                            <div style={styles.subTitle}>
                                BBQ Pit Cancellation Policy
                            </div>
                            <ul>
                                <li>In the event of cancellation or inclement weather, 100% of BBQ pit booking charges are strictly non-refundable.</li>
                            </ul>
                            <div style={styles.subTitle}>
                                No-Show / Early Departure
                            </div>
                            <ul>
                                <li>In the event of a no-show, early departure or if guests fail to check-in on their stipulated date of arrival, 100% of room charges are strictly non-refundable.</li>
                            </ul>
                        </div>*/ }
                    </div>
                </div>
            </div>
        </div>;
    }
}
