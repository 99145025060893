import React from 'react';
import { _formatDecimalAmount } from '../functions/helper_functions';
import moment from 'moment';

// temporary
import * as config from '../const/config';

export default class BookingDetailRoom extends React.Component{
    static defaultProps = {
        bookingDetail: null
    }
    constructor(props){
        super(props);

        let bookingDetail = this.props.bookingDetail;
        let isLongTermCode = false;
        if ( this.props.bookingDetail.special_code_type == 'Long Term Code' ) {
            isLongTermCode = true;
            //bookingDetail.grand_total = 0;
            //bookingDetail.room_charge = 0;
            //bookingDetail.room_tax = 0;
        }

        this.state={
            bookingDetail: bookingDetail,
            addons: this.props.addons,
            isLongTermCode: isLongTermCode,
            isModify: this.props.isModify
        }

        // temporary
        this.handleUpdateRoom = this.handleUpdateRoom.bind(this);
    }

    // temporary
    handleUpdateRoom() {
				localStorage.setItem(config.KEY_UPDATE_TOKEN, this.state.bookingDetail.update_token);
				localStorage.setItem(config.KEY_UPDATE_BOOKING_ID, this.state.bookingDetail.id);
				localStorage.setItem(config.KEY_IS_MEMBER_PREVIOUS_BOOKING, this.state.bookingDetail.is_member);
				localStorage.setItem(config.KEY_CHECK_IN_DATE, this.state.bookingDetail.check_in_date);
				localStorage.setItem(config.KEY_CHECK_OUT_DATE, this.state.bookingDetail.check_out_date);
				localStorage.setItem(config.KEY_TOTAL_NIGHT, this.state.bookingDetail.total_nights);
				localStorage.setItem(config.KEY_TOTAL_ROOMS, this.state.bookingDetail.total_rooms);
				localStorage.setItem(config.KEY_TOTAL_ADULT, this.state.bookingDetail.total_adults);
				localStorage.setItem(config.KEY_TOTAL_CHILDREN, this.state.bookingDetail.total_children);
				localStorage.setItem(config.KEY_SPECIAL_CODE_TYPE, this.state.bookingDetail.special_code_type);
				localStorage.setItem(config.KEY_SPECIAL_CODE, this.state.bookingDetail.special_code);

				window.location.href = config.WEBSITE_URL;
		}

    loopChargeBreakdown(chargeBreakdown) {
				let dataToReturn = [];
				for ( let chargeDate in chargeBreakdown ) {
						dataToReturn.push(
								<tr key={chargeDate}>
										<td>{moment(chargeDate).format('DD MMM YYYY')}</td>
										<td style={{ paddingLeft: '45px' }}>S$ { this.state.isLongTermCode ? '0.00' : chargeBreakdown[chargeDate] }</td>
								</tr>
						);
				}

				return dataToReturn;
		}

    renderAddons() {
        let addons = [];
        if ( this.state.addons.length > 0 ) {
            addons.push(
                <tr key="addon">
                    <td colSpan="2"><strong>Addon(s):</strong></td>
                </tr>
            )

            for ( let index in this.state.addons ) {
                let addon = this.state.addons[index];

                addons.push(
                    <tr key={index}>
                      <th>
                        <div style={{fontSize:'14px', fontWeight:'normal'}}>
                            { addon.name } ({ moment(addon.from).format('DD MMM YYYY') }{ addon.from != addon.to ? " - " + moment(addon.to).format('DD MMM YYYY') : null }) x { addon.qty }
                        </div>
                      </th>
                      <th>
                        <div style={{fontSize:'14px', textAlign:'right'}}>
                            S${ _formatDecimalAmount(addon.amount) }
                        </div>
                      </th>
                    </tr>
                );
            }
        }

        return addons;
    }

    render(){
        let totalNights = 0;
        let checkInDate = '';
        let checkOutDate = '';
        let bbqDates = '';
        let specialRequest = '';
        let roomPreferences = '';
        let chargeBreakdown = '';
        let policy = '';

        checkInDate = moment(this.state.bookingDetail.check_in_date);
        checkOutDate = moment(this.state.bookingDetail.check_out_date);

        totalNights = checkOutDate.diff(checkInDate, 'day');

        if ( this.state.bookingDetail.bbq_pit_dates !== null && this.state.bookingDetail.bbq_pit_dates !== "" ) {
            if ( this.state.bookingDetail.bbq_pit_dates.indexOf(",") > -1 ) {
                let bbqDatesCount = 1;
                bbqDates = this.state.bookingDetail.bbq_pit_dates.split(',').map(i => {
                    return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}} key={bbqDatesCount++}>{i}</p>
                });
            }
            else {
                bbqDates = this.state.bookingDetail.bbq_pit_dates;
            }
        }

        if ( this.state.bookingDetail.special_request &&  this.state.bookingDetail.special_request != "" ) {
            let specialRequestCount = 1;
            
            if ( Array.isArray(this.state.bookingDetail.special_request) ) {
                specialRequest = this.state.bookingDetail.special_request.map(i => {
                    return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px', wordBreak: 'break-word'}} key={specialRequestCount++}>{i}</p>
                });
            }
            else {
                specialRequest = this.state.bookingDetail.special_request.split('\n').map(i => {
                    return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px', wordBreak: 'break-word'}} key={specialRequestCount++}>{i}</p>
                });
            }
        }

        if ( this.state.bookingDetail.room_preference_codes != "" ) {
            let roomPreferencesCount = 1;
            roomPreferences = this.state.bookingDetail.room_preference_codes.split(',').map(i => {
                return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}} key={roomPreferencesCount++}>{i}</p>
            });
        }

        chargeBreakdown = <table className=""><tbody>{ this.loopChargeBreakdown(this.state.bookingDetail.charge_breakdown) }</tbody></table>;

        let inclusivePackagesDisplay = null;
        if ( this.state.bookingDetail.rate_plan_packages != "" && this.state.bookingDetail.rate_plan_packages !== '[]' && this.state.bookingDetail.rate_plan_packages.length > 0 ) {
            inclusivePackagesDisplay = this.state.bookingDetail.rate_plan_packages.map(i => {
                return <p key={ i.name } style={{ textAlign: 'left', marginBottom: '0', paddingLeft: '15px' }}>{i.name}</p>
            });
        }

        let hasPreviousBooking = this.state.bookingDetail.previous_booking_id !== undefined && this.state.bookingDetail.previous_booking_id !== null && this.state.bookingDetail.previous_booking_id !== 'null';

        return(
            <div className="booking-detail-room">
                <div style={{margin:'20px auto', textAlign:'left'}}>

                  <table style={{width:'100%'}} border="0">
                    <tbody>
                     <tr>
                      <th>

                      </th>
                      <th>
                        <div style={{paddingTop:'10px'}}>
                          <div style={{color:'#285531',fontSize:'18px', fontWeight:'bold', position: 'relative'}}>
                              { this.state.bookingDetail.room_type_name }

                              { this.state.isModify ?
                              <div className="edit-button-container hide-mobile">
                                  <button className="btn-md btn btn-success btn-square" onClick={ this.handleUpdateRoom }>
                                      Change Room Booking
                                  </button>
                              </div> : null
                              }
                          </div>
                        </div>
                      </th>
                     </tr>
                    </tbody>
                  </table>

                </div>



                <div style={{textAlign:'left', marginTop:'10px', marginBottom:'20px', padding:'20px 30px 20px 30px', backgroundColor:'#fff', boxShadow: '0 15px 20px rgba(0, 0, 0, 0.3)'}}>


                  <div style={{fontWeight:'bold', fontSize:'16px', paddingLeft:'2px'}}>
                      { this.state.bookingDetail.rate_plan_name }
                      {
                        inclusivePackagesDisplay ?
                        <div style={{ marginBottom: '15px', marginTop: '10px' }}>
                          <p style={{ textAlign: 'left', marginBottom: '0' }}><strong>Inclusive Of</strong></p>
                          { inclusivePackagesDisplay }
                        </div>
                        : null
                      }
                  </div>


                  <table style={{width:'100%', paddingTop:'25px'}} border="0">
                    <tbody>
                      <tr>
                      <th>
                        <div style={{fontSize:'14px', fontWeight:'normal'}}>
                            Room Charges
                        </div>
                      </th>
                      <th>
                        <div style={{fontSize:'14px', textAlign:'right'}}>
                            S${ _formatDecimalAmount( this.state.bookingDetail.room_charge ) }
                        </div>
                      </th>
                     </tr>
                     <tr>
                        <td colSpan="2" style={{ fontSize: '14px', paddingLeft: '15px', textAlign:'left', paddingBottom: '15px' }}>
                            {chargeBreakdown}
                        </td>
                     </tr>
                     { ( this.state.bookingDetail.bbq_pit_dates !== "" && this.state.bookingDetail.bbq_pit_dates !== undefined && this.state.bookingDetail.bbq_pit_dates !== null ) ?
                    <tr>
                      <th>
                        <div style={{fontSize:'14px', fontWeight:'normal'}}>
                            BBQ Pit Charges
                        </div>
                      </th>
                      <th>
                        <div style={{fontSize:'14px', textAlign:'right'}}>
                            S${ _formatDecimalAmount(this.state.bookingDetail.bbq_pit_charge) }
                        </div>
                      </th>
                    </tr> : null }

                    { ( this.state.bookingDetail.bbq_pit_dates !== "" && this.state.bookingDetail.bbq_pit_dates !== undefined && this.state.bookingDetail.bbq_pit_dates !== null ) ?
                     <tr>
                       <td colSpan="2" style={{ fontSize: '14px', paddingLeft: '15px', textAlign:'left', paddingBottom: '15px' }}>
                           { bbqDates }
                       </td>
                     </tr> : null }

                     { this.state.addons.length > 0 ? this.renderAddons() : null }
                    </tbody>
                  </table>

                  <div style={{borderBottom:'3px solid #e1e1e1', paddingTop:'15px'}}></div>


                  <table style={{width:'100%',marginTop:'15px',fontWeight:'bold'}} border="0">
                    <tbody>
                      <tr>
                       <th style={{width:'30%'}}></th>
                       <th style={{width:'40%', textAlign:'right'}}>
                         <div style={{fontSize:'14px'}}>
                           Subtotal
                         </div>
                       </th>
                       <th style={{width:'30%'}}>
                         <div style={{fontSize:'14px',textAlign:'right'}}>
                           S$
                           {
                             _formatDecimalAmount(this.state.bookingDetail.grand_total - (this.state.bookingDetail.room_tax + this.state.bookingDetail.bbq_pit_tax + this.state.bookingDetail.addons_tax + this.state.bookingDetail.non_refundable_amount))
                             //_formatDecimalAmount(this.state.bookingDetail.grand_total - (this.state.bookingDetail.room_tax + this.state.bookingDetail.bbq_pit_tax + this.state.bookingDetail.addons_tax))
                           }
                         </div>
                       </th>
                     </tr>

                      <tr>
                        <th style={{width:'30%'}}></th>
                        <th style={{width:'40%', textAlign:'right'}}>
                          <div style={{fontSize:'14px'}}>
                            Tax
                          </div>
                        </th>
                        <th style={{width:'30%'}}>
                          <div style={{fontSize:'14px', textAlign:'right'}}>
                              S${ _formatDecimalAmount(this.state.bookingDetail.room_tax + this.state.bookingDetail.bbq_pit_tax + this.state.bookingDetail.addons_tax) }
                          </div>
                        </th>
                      </tr>

                      { ( !hasPreviousBooking && this.state.bookingDetail.non_refundable_amount > 0 ) ?
                        <tr>
                         <th style={{width:'30%'}}></th>
                         <th style={{width:'40%', textAlign:'right', borderBottom: '1px solid rgba(0, 0, 0, 0.5)'}}>
                           <div style={{fontSize:'14px'}}>
                             Non-Refundable Amount
                           </div>
                         </th>
                         <th style={{width:'30%', borderBottom: '1px solid rgba(0, 0, 0, 0.5)'}}>
                           <div style={{fontSize:'14px',textAlign:'right'}}>
                             S${ _formatDecimalAmount(this.state.bookingDetail.non_refundable_amount) }
                           </div>
                         </th>
                       </tr>
                        : null
                     }

                      { hasPreviousBooking ?
                        null
                        :
                        <tr>
                         <th style={{width:'30%'}}></th>
                         <th style={{width:'40%', textAlign:'right'}}>
                           <div style={{fontSize:'14px'}}>
                             Grand Total
                           </div>
                         </th>
                         <th style={{width:'30%'}}>
                           <div style={{fontSize:'14px',textAlign:'right'}}>
                             S${ _formatDecimalAmount(this.state.bookingDetail.grand_total) }
                           </div>
                         </th>
                       </tr>
                     }

                     { ( hasPreviousBooking && this.state.bookingDetail.modify_difference_amount == 0 ) ?
                       <tr>
                        <th style={{width:'30%'}}></th>
                        <th style={{width:'40%', textAlign:'right'}}>
                          <div style={{fontSize:'14px'}}>
                            Deposit
                          </div>
                        </th>
                        <th style={{width:'30%'}}>
                          <div style={{fontSize:'14px',textAlign:'right'}}>
                            -S${ _formatDecimalAmount(this.state.bookingDetail.previous_booking_amount) }
                          </div>
                        </th>
                      </tr>
                        : null
                     }

                     { ( hasPreviousBooking && this.state.bookingDetail.modify_difference_amount > 0 ) ?
                       <tr>
                        <th style={{width:'30%'}}></th>
                        <th style={{width:'40%', textAlign:'right'}}>
                          <div style={{fontSize:'14px'}}>
                            Deposit
                          </div>
                        </th>
                        <th style={{width:'30%'}}>
                          <div style={{fontSize:'14px',textAlign:'right'}}>
                            -S${ _formatDecimalAmount(this.state.bookingDetail.previous_booking_amount) }
                          </div>
                        </th>
                      </tr>
                       : null
                    }

                    { ( hasPreviousBooking && this.state.bookingDetail.non_refundable_amount > 0 ) ?
                      <tr>
                       <th style={{width:'30%'}}></th>
                       <th style={{width:'40%', textAlign:'right', borderBottom: '1px solid rgba(0, 0, 0, 0.5)'}}>
                         <div style={{fontSize:'14px'}}>
                           Non-Refundable Amount
                         </div>
                       </th>
                       <th style={{width:'30%', borderBottom: '1px solid rgba(0, 0, 0, 0.5)'}}>
                         <div style={{fontSize:'14px',textAlign:'right'}}>
                           S${ _formatDecimalAmount(this.state.bookingDetail.non_refundable_amount) }
                         </div>
                       </th>
                     </tr>
                      : null
                   }

                    { hasPreviousBooking ?
                     <tr>
                      <th style={{width:'30%'}}></th>
                      <th style={{width:'40%', textAlign:'right'}}>
                        <div style={{fontSize:'14px'}}>
                          Outstanding Payment
                        </div>
                      </th>
                      <th style={{width:'30%'}}>
                        <div style={{fontSize:'14px',textAlign:'right'}}>
                          S${ _formatDecimalAmount(this.state.bookingDetail.modify_difference_amount) }
                        </div>
                      </th>
                    </tr>
                     : null
                    }

                      <tr>
                        <td colSpan="3" style={{ fontSize: '14px', fontStyle: 'italic', fontWeight: 'normal', paddingTop: '10px' }}>With the adjustment of prevailing taxes, guests visiting D'Resort with stay date from 1 January 2023 onwards will have to bear the cost of any variance in Government Tax upon check-in.</td>
                      </tr>
                    </tbody>

                  </table>

                </div>
            </div>
        )
    }
}
