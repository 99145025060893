import * as types from '../const/types';

export default (state = {}, action) => {
    switch (action.type) {
        case types.CONTENT_RETURN:
            return { ...state,
                result: action.payload,
            }
        case types.ERROR:
            return { ...state,
                error: action.payload
            }
        default:
            return state;
    }
}
