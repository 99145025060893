import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobileView } from '../../misc/helper_functions';
import { _getLocalStorage, _formatDateCheckInOut, _formatDecimalAmount, _formatDate } from '../../functions/helper_functions';
import { _loading } from '../../functions/message_functions';
import $ from 'jquery';
import moment from 'moment';
import SweetAlert from 'sweetalert2';

import BookingDetail from '../../component/BookingDetail';
import PreRegister from '../../component/PreRegister';
import BookingDetailRoom from '../../component/BookingDetailRoom';

import DOMPurify from 'dompurify';

import * as allAPI from '../../apis/all';
import * as config from '../../const/config';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

var lastHeight = 0;
var lastOffSet = 0;

class ManageBooking extends React.Component{
		constructor(props){
				super(props);

				let totalRateAndPackages = _getLocalStorage(config.KEY_TOTAL_ROOM_RATE_AND_PACKAGES);
				let totalTaxesAndFees = _getLocalStorage(config.KEY_TOTAL_TAXES_AND_FEES);
				let totalAddons = _getLocalStorage(config.KEY_TOTAL_PACKAGES_AMOUNT);
				let totalAddonsTax = _getLocalStorage(config.KEY_TOTAL_PACKAGE_TAX_AND_FEES);
				let grandTotal = _getLocalStorage(config.KEY_GRAND_TOTAL);
				let addons = _getLocalStorage(config.KEY_ADDONS);
				let totalRooms = _getLocalStorage(config.KEY_TOTAL_ROOMS);
				let totalAdult = _getLocalStorage(config.KEY_TOTAL_ADULT);
				let totalChildren = _getLocalStorage(config.KEY_TOTAL_CHILDREN);
				let specialCodeType = _getLocalStorage(config.KEY_SPECIAL_CODE_TYPE);
				let specialCode = _getLocalStorage(config.KEY_SPECIAL_CODE);
				let addonCharges = _getLocalStorage(config.KEY_ADDON_CHARGES);
				let inclusivePackages = _getLocalStorage(config.KEY_INCLUSIVE_PACKAGE);
				let addonChargeBreakdown = _getLocalStorage(config.KEY_ADDON_CHARGES_BREAKDOWN);
				let isShowAddonBreakdown = [];
				let hasInclusivePackage = false;

				if ( totalRateAndPackages ) { totalRateAndPackages = parseFloat(totalRateAndPackages.replace(/,/, "")); }
				if ( totalTaxesAndFees ) { totalTaxesAndFees = parseFloat(totalTaxesAndFees.replace(/,/, "")); }
				if ( totalAddons ) { totalAddons = parseFloat(totalAddons.replace(/,/, "")); }
				if ( totalAddonsTax ) { totalAddonsTax = parseFloat(totalAddonsTax.replace(/,/, "")); }
				if ( grandTotal ) { grandTotal = parseFloat(grandTotal.replace(/,/, "")); }
				if ( addons ) { addons = JSON.parse(addons); }

				let specialRequests = JSON.parse(_getLocalStorage(config.KEY_SPECIAL_REQUESTS));
				let preference = '';

				// Initial qty for add-ons
				for ( let index in addons ) {
						let addon = addons[index];
						isShowAddonBreakdown[addon.id] = false;
				}

				let summaryAddonsCharges = new Array();
				if ( addonCharges ) {
						addonCharges = JSON.parse(addonCharges);

						for ( let index = 0; index < addonCharges.length; index++ ) {
								if ( addonCharges[index] ) {
										summaryAddonsCharges[addonCharges[index].id] = {};
										summaryAddonsCharges[addonCharges[index].id].title = addonCharges[index].title;
										summaryAddonsCharges[addonCharges[index].id].amount = addonCharges[index].amount;
								}
						}
				}

				let inclusivePackagesDisplay = '';
				if ( inclusivePackages && inclusivePackages !== '[]' && inclusivePackages.length > 0 ) {
						hasInclusivePackage = true;
						inclusivePackages = JSON.parse(inclusivePackages);

						for ( let index in inclusivePackages ) {
								inclusivePackagesDisplay += "<br />" + inclusivePackages[index].name;
						}

						inclusivePackagesDisplay = inclusivePackagesDisplay.substring("<br />".length);
				}

				this.state={
						checkInDate: _getLocalStorage(config.KEY_CHECK_IN_DATE),
						checkOutDate: _getLocalStorage(config.KEY_CHECK_OUT_DATE),
						totalNight: _getLocalStorage(config.KEY_TOTAL_NIGHT),
						roomCharge: totalRateAndPackages,
						chargeBreakdown: _getLocalStorage(config.KEY_CHARGE_BREAKDOWN),
						totalAddons: totalAddons,
						totalAddonsTax: totalAddonsTax !== null ? parseFloat(totalAddonsTax) : 0,
						totalRooms: totalRooms,
						tax: totalTaxesAndFees,
						grandTotal: grandTotal,
						roomName: _getLocalStorage(config.KEY_ROOM_NAME),
						specialRequests: specialRequests,
						specialRequestsTemmp: specialRequests,
						timeArrival: "I don't know",
						timeArrivalTemp: '',
						name: '',
						guestName: '',
						guestNameUpdate: '',
						guestNameUpdateTemp: '',
						guestEmail: '',
						guestEmailUpdate: '',
						guestIDD: '+65',
						guestIDDTemp: '+65',
						guestMobile: '',
						guestMobileTemp: '',
						bedPreference: '',
						bedPreferenceDisplay: '',
						specialRequest: [],
						isBookingForOther: false,
						comments: '',
						commentsTemp: '',
						isAllowedMakePayment: false,
						isAgreeTermPrivacyPolicy: false,
						roomPreferences: [],
						selectedRoomPreferences: [],
						roomPreferencesTemp: [],
						roomPreferencesTempDisplay: [],
						bedPreferences: [],
						bedPreferencesTemp: [],
						addons: addons,
						isSubmitBooking: false,
						isEmailValid:true,
						isGuestNameValid: true,
						isShowChargeBreakdown: false,
						isShowAddonBreakdown: isShowAddonBreakdown,
						errorMessage: null,
						totalAdult: totalAdult,
						totalChildren: totalChildren !== null ? totalChildren : 0,
						specialCodeType: specialCodeType !== null ? specialCodeType : null,
						specialCode: specialCode !== null ? specialCode : null,
						summaryAddonsCharges: summaryAddonsCharges,
						inclusivePackages: inclusivePackages !== null ? inclusivePackages : null,
						inclusivePackagesDisplay: inclusivePackagesDisplay !== null ? inclusivePackagesDisplay : null,
						addonChargeBreakdown: addonChargeBreakdown !== null ? JSON.parse(addonChargeBreakdown) : {},
						hasInclusivePackage: hasInclusivePackage,


	          bookingID: '',
	          bookingDetailErrorMessage: '',
	          isRetrieveBooking: false,
	          bookingDetail: null,
						addons: null,
						toCancel: false,
						toModify: false,
						toPreRegister: false,
						step: 1,
						cancellationToken: '',
						isCancelling: false,
						isCancelled: false,
						isNotPaidStatus: false,
						cancelErrorMessage: '',
						cancellationReason: '',
						cancellationType: 'Personal reason, change of plan',
						bookingStatusText: '',
						addonBBQAmount: 0,
						isModified: false,
						isGuestDetailsModified: false,
						isBookingDetailsModified: false,
						isBookingRequestModified: false,
						isStatusCancelled: false,

						countries: null,
						nationalities: null
				}

				this.handleData = this.handleData.bind(this);
				this.handleRoomPreferences = this.handleRoomPreferences.bind(this);
				this.handleBedPreferences = this.handleBedPreferences.bind(this);
				this.confirmCancellation = this.confirmCancellation.bind(this);

				this.handleUpdateGuestDetails = this.handleUpdateGuestDetails.bind(this);
				this.handleProcessModification = this.handleProcessModification.bind(this);
				this.handleUpdateBookingRequest = this.handleUpdateBookingRequest.bind(this);
				this.handleUpdateRoom = this.handleUpdateRoom.bind(this);
				this.handleUpdateAddon = this.handleUpdateAddon.bind(this);
		}

		checkIsBedPreference(preference) {
				let isBedReference = false;
				let bedPreferences = ["single", "double", "twin", "queen"];
				let bedPreference = '';
				let preferenceName = preference.name.toLowerCase();
				for ( let key in bedPreferences ) {
						bedPreference = bedPreferences[key];

						if ( preferenceName.indexOf(bedPreference) > -1 ) {
								isBedReference = true;
								break;
						}
				}

				return isBedReference;
		}

		filter_array(test_array) {
				let index = -1;
				const arr_length = test_array ? test_array.length : 0;
				let resIndex = -1;
				const result = [];

				while (++index < arr_length) {
						const value = test_array[index];

						if (value) {
								result[++resIndex] = value;
						}
				}

				return result;
		}

		componentDidMount() {
				//document.addEventListener('scroll',this.handleScroll);
				window.scrollTo(0, 0);
				$("#login").hide();
		}

		componentWillUnmount(){
				//document.removeEventListener('scroll',this.handleScroll);
		}

		setTimeOfArrival(event) {
				this.setState({ timeOfArrivalTemp: event.target.value });
		}

		setCancellationType(event) {
				this.setState({ cancellationType: event.target.value });
		}

		loopChargeBreakdown(chargeBreakdown) {
				let dataToReturn = [];
				for ( let chargeDate in chargeBreakdown ) {
						dataToReturn.push(
								<tr key={chargeDate}>
										<td>{moment(chargeDate).format('DD MMM YYYY')}</td>
										<td style={{ paddingLeft: '45px' }}>S$ {chargeBreakdown[chargeDate]}</td>
								</tr>
						);
				}

				return dataToReturn;
		}

		handleData(event,target) {
				event.persist();
				const iddReg = /^\+?\d*$/;
				const setTheData = () => {
					this.setState({ [event.target.name]: event.target.value });
				}
				if(target==='mobile'){
					if(event.target.validity.valid){
						setTheData();
					}
				}else if(target==='IDD'){
					if(iddReg.test(event.target.value)){
						setTheData();
					}
				}
				else{
					setTheData();
				}
		}

		handleRoomPreferences(event) {
				let roomPreferences = this.state.roomPreferencesTemp;
				let roomPreferencesDisplay = this.state.roomPreferencesTempDisplay;

				if ( event.target.checked ) {
						roomPreferences.push(event.target.value);
						roomPreferencesDisplay.push(event.target.name);
				}
				else {
						roomPreferences.splice(roomPreferences.indexOf(event.target.value), 1);
						roomPreferencesDisplay.splice(roomPreferencesDisplay.indexOf(event.target.name), 1);
				}

				this.setState({ roomPreferencesTemp: roomPreferences, roomPreferencesTempDisplay: roomPreferencesDisplay });
		}

		handleBedPreferences(event) {
				this.setState({ bedPreference: event.target.value, bedPreferenceDisplay: event.target.name });
		}

		handleUpdateRoom() {
				localStorage.setItem(config.KEY_UPDATE_TOKEN, this.state.bookingDetail.update_token);
				localStorage.setItem(config.KEY_UPDATE_BOOKING_ID, this.state.bookingID);
				localStorage.setItem(config.KEY_IS_MEMBER_PREVIOUS_BOOKING, this.state.bookingDetail.is_member);
				localStorage.setItem(config.KEY_CHECK_IN_DATE, this.state.bookingDetail.check_in_date);
				localStorage.setItem(config.KEY_CHECK_OUT_DATE, this.state.bookingDetail.check_out_date);
				localStorage.setItem(config.KEY_TOTAL_NIGHT, this.state.bookingDetail.total_nights);
				localStorage.setItem(config.KEY_TOTAL_ROOMS, this.state.bookingDetail.total_rooms);
				localStorage.setItem(config.KEY_TOTAL_ADULT, this.state.bookingDetail.total_adults);
				localStorage.setItem(config.KEY_TOTAL_CHILDREN, this.state.bookingDetail.total_children);
				localStorage.setItem(config.KEY_SPECIAL_CODE_TYPE, this.state.bookingDetail.special_code_type);
				localStorage.setItem(config.KEY_SPECIAL_CODE, this.state.bookingDetail.special_code);

				window.location.href = config.WEBSITE_URL;
		}

		handleUpdateAddon() {
				localStorage.setItem(config.KEY_UPDATE_TOKEN, this.state.bookingDetail.update_token);
				localStorage.setItem(config.KEY_UPDATE_BOOKING_ID, this.state.bookingID);

				this.props.history.push('/addons/amend');
		}

		componentWillReceiveProps(nextProps) {
        if ( this.state.isRetrieveBooking && nextProps.retrieveBookingStatus ) {
						if ( nextProps.data.error ) {
								this.setState({ bookingDetailErrorMessage: nextProps.data.message });
            }
            else {
								if ( !nextProps.data.bookingDetail.cancellation_admin_fee ) {
										nextProps.data.bookingDetail.cancellation_admin_fee = 0;
								}

								if ( nextProps.data.bookingDetail.status !== 'paid' ) {
										this.setState({ isNotPaidStatus: true });

										let bookingStatusText = '';
										if ( nextProps.data.bookingDetail.status == 'cancelled_with_no_refund' ) {
												bookingStatusText = 'cancelled with no refund';
												this.setState({ isStatusCancelled: true });
										}
										else if ( nextProps.data.bookingDetail.status == 'cancelled_with_refund' ) {
												bookingStatusText = 'cancelled with refund';
												this.setState({ isStatusCancelled: true });
										}
										else if ( nextProps.data.bookingDetail.status == 'cancelled' ) {
												bookingStatusText = 'cancelled';
												this.setState({ isStatusCancelled: true });
										}
										else {
												bookingStatusText = 'pending cancellation';
										}

										this.setState({ bookingStatusText: bookingStatusText });
								}

								this.setState({ guestEmailUpdate: nextProps.data.bookingDetail.email,
										guestNameUpdate: nextProps.data.bookingDetail.name,
										guestNameUpdateTemp: nextProps.data.bookingDetail.name,
										guestIDD: nextProps.data.bookingDetail.idd_code,
										guestIDDTemp: nextProps.data.bookingDetail.idd_code,
										guestMobile: nextProps.data.bookingDetail.mobile,
										guestMobileTemp: nextProps.data.bookingDetail.mobile,
									 	comments: nextProps.data.bookingDetail.special_request,
										commentsTemp: nextProps.data.bookingDetail.special_request,
									 	timeOfArrival: nextProps.data.bookingDetail.time_of_arrival,
										timeOfArrivalTemp: nextProps.data.bookingDetail.time_of_arrival,
										bedPreferenceDisplay: nextProps.data.bookingDetail.bed_preference_codes,
										countries: nextProps.data.countries,
										nationalities: nextProps.data.nationalities
								});

								if ( nextProps.data.bookingDetail.room_preference_codes ) {
										this.setState({ selectedRoomPreferences: nextProps.data.bookingDetail.room_preference_codes.split(','),
											roomPreferencesTempDisplay: nextProps.data.bookingDetail.room_preference_codes.split(',') });
								}

                this.setState({ bookingDetail: nextProps.data.bookingDetail, addons: nextProps.data.addons, addonBBQAmount: nextProps.data.addonBBQAmount , step: 2 });
                window.scrollTo(0, 0);

								let roomPreferences = [];
								let bedPreferences = [];
								let roomPreferencesTemp = [];
								let selectedRoomPreferenceNames = nextProps.data.bookingDetail.room_preference_codes.split(',');
								if ( nextProps.data.bookingDetail.specialRequests ) {
										let specialRequests = nextProps.data.bookingDetail.specialRequests;

										for ( let specialRequestId in specialRequests ) {
												let preference = specialRequests[specialRequestId];

												if ( !this.checkIsBedPreference(preference) ) {
														roomPreferences[specialRequestId] = preference;

														if ( selectedRoomPreferenceNames.indexOf(preference.name) > -1 ) {
																roomPreferencesTemp.push(preference.code);

														}
												}
												else {
														bedPreferences[specialRequestId] = preference;
														if ( nextProps.data.bookingDetail.bed_preference_codes == preference.name ) {
																this.setState({ bedPreference: preference.code });
														}
												}
										}

										roomPreferences = this.filter_array(roomPreferences);
										bedPreferences = this.filter_array(bedPreferences);
								}

								this.setState({ roomPreferences, bedPreferences, roomPreferencesTemp });
            }

            this.setState({ isRetrieveBooking: false });
				}

				if ( this.state.isCancelling && nextProps.cancelBookingStatus ) {
						if ( nextProps.cancelBookingData.error ) {
								this.setState({ cancelErrorMessage: nextProps.cancelBookingData.message });
						}
						else {
								this.setState({ isCancelled: true });
						}

						this.setState({ isCancelling: false });
				}

				if ( this.state.isModifying && nextProps.modifyBookingStatus ) {
						if ( nextProps.modifyBookingData.error ) {
								this.setState({ cancelErrorMessage: nextProps.cancelBookingData.message });
						}
						else {
								this.setState({ isModified: true, isGuestDetailsModified: false, isBookingRequestModified: false });

								SweetAlert.fire({icon:'success',
										title:'Changed',
										html: '<p class="text-center">' + nextProps.modifyBookingData.message + '</p>',
										buttonsStyling: false,
										confirmButtonText: 'Ok',
										customClass:{confirmButton:'btn btn-success'}});
						}

						this.setState({ isModifying: false });
				}
		}

		checkEmailValidation = () => {
				if ( this.state.guestEmailUpdate == "" ) {
						return true;
				}
    		const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    		return emailReg.test(this.state.guestEmail);
  	}
  	checkNameValidation = () => {
  		  const nameReg = /^([a-zA-Z\s\/',\-\.]*)$/;
        return nameReg.test(this.state.guestNameUpdate);
  	}
  	handleEmailBlur = () => {
    		if(this.checkEmailValidation(this.state.guestEmailUpdate)){
    			this.setState({isEmailValid:true});
    		}
        else{
    			this.setState({isEmailValid:false})
    		}
  	}
  	handleNameBlur = () => {
				if(this.checkNameValidation()){
    			this.setState({isGuestNameValid:true});
    		}
        else{
    			this.setState({isGuestNameValid:false});
    		}
  	}

		handleUpdateGuestDetails = () => {
				this.setState({ guestNameUpdate: this.state.guestNameUpdateTemp, guestIDD: this.state.guestIDDTemp, guestMobile: this.state.guestMobileTemp, isGuestDetailsModified: true });
		}

		handleUpdateBookingRequest = () => {
				this.setState({ comments: this.state.commentsTemp,
					timeOfArrival: this.state.timeOfArrivalTemp, bedPreference: this.state.bedPreference,
					selectedRoomPreferences: this.state.roomPreferencesTempDisplay,
					isBookingRequestModified: true });
		}

		handleProcessModification = () => {
				SweetAlert.fire({icon:'warning',
						title:'Are you sure?',
						html: '<p class="text-center">Please confirm to process changes.</p>',
						buttonsStyling: false,
						showCancelButton: true,
						confirmButtonText: 'Confirm',
						cancelButtonText: 'Cancel',
						customClass:{confirmButton:'btn btn-success', cancelButton: 'btn btn-default'}})
				.then((result) => {
						if (result.value) {
								this.setState({ isModifying: true, isModified: false });

								this.props.modifyBooking(this.state.bookingID, this.state.guestNameUpdate, this.state.guestIDD, this.state.guestMobile,
										this.state.isGuestDetailsModified,
										this.state.roomPreferencesTemp, this.state.bedPreference, this.state.timeOfArrivalTemp, this.state.commentsTemp,
										this.state.isBookingRequestModified,
										this.state.isBookingDetailsModified, this.state.bookingDetail.update_token);//this.state.bookingDetail.update_token);
						}
				});
		}

    retrieveBooking = () => {
				this.setState({ errorMessage: null, bookingDetailErrorMessage: '' });
				if ( this.state.bookingID.trim() == '' ) {
						this.setState({ errorMessage: 'Booking ID cannot be empty.' });
				}
				else {
						if ( this.state.guestName.trim() == "" && this.state.guestEmail.trim() == "" ) {
								this.setState({ errorMessage: 'Guest Name and Email cannot be both empty. You need to fill in one of them.' });
						}
						else {
				        this.setState({ bookingDetailErrorMessage: '', isRetrieveBooking: true });
				        this.props.retrieveBooking(this.state.bookingID, this.state.guestName, this.state.guestEmail);
						}
				}
    }

		confirmCancellation = (canCancel) => {
				if ( !canCancel ) {
						SweetAlert.fire({icon:'warning',
								title:'Are you sure?',
								html: '<p class="text-center">Please confirm to process cancellation without refund.</p>',
								buttonsStyling: false,
								showCancelButton: true,
								confirmButtonText: 'Confirm',
								cancelButtonText: 'Cancel',
								customClass:{confirmButton:'btn btn-success', cancelButton: 'btn btn-default'}})
						.then((result) => {
								if (result.value) {
										this.setState({ isCancelling: true, cancelErrorMessage: '', isCancelled: false });

										this.props.cancelBooking(this.state.bookingID, this.state.cancellationType, this.state.cancellationReason, this.state.bookingDetail.update_token);
							  }
						});
				}
				else {
						SweetAlert.fire({icon:'warning',
								title:'Are you sure?',
								html: '<p class="text-center">Please confirm to process cancellation.</p>',
								buttonsStyling: false,
								showCancelButton: true,
								confirmButtonText: 'Confirm',
								cancelButtonText: 'Cancel',
								customClass:{confirmButton:'btn btn-success', cancelButton: 'btn btn-default'}})
						.then((result) => {
								if (result.value) {
										this.setState({ isCancelling: true, cancelErrorMessage: '', isCancelled: false });

										this.props.cancelBooking(this.state.bookingID, this.state.cancellationType, this.state.cancellationReason, this.state.bookingDetail.update_token);
								}
						});
				}
		}

    renderRetrieveBookingForm() {
        return (
            <div className="row mt-2">
                <div className="col-12 checkout-form">

                    <div className="form-group">
                        <label htmlFor="usr">Booking ID </label>
                        <input name="bookingID" maxLength="15" type="text" className="form-control col-12 col-md-6" value={ this.state.bookingID } onChange={ this.handleData } placeholder="D'Resort Booking Confirmation ID" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="usr">Guest Name</label>
                        <input name="guestName" type="text" className="form-control col-12 col-md-6" value={ this.state.guestName } onBlur={ this.handleNameBlur } onChange={ this.handleData } placeholder="Full name as shown on Passport or NRIC" />
                        {
                            this.state.isGuestNameValid ? null :
                            <p style={{
                              marginTop: '7px',
                              color: 'red',
                              fontWeight: 'bold',
                            }}>Guest Name is Invalid. Guest Name can only contains alphabet, space, and special characters such as /, ', -, ., and (,).</p>
                        }
                  	</div>

										<div className="form-group">
												<p className="text-green"><strong>and / or</strong></p>
										</div>

	                  <div className="form-group">
	                      <label htmlFor="usr">Email</label>
	                      <input onBlur={this.handleEmailBlur} name="guestEmail" type="email" className="form-control col-12 col-md-6" value={ this.state.guestEmail } onChange={ this.handleData } placeholder="Provide accurate email according to booking confirmation" />
	                      {
	                          this.state.isEmailValid ? null :
	                          <p style={{
	                            marginTop: '7px',
	                            color: 'red',
	                            fontWeight: 'bold',
	                          }}>Email is Invalid</p>
	                      }
	                  </div>

	                  <div className="form-group">
	                      {
	                          this.state.bookingDetailErrorMessage == '' ? null :
														<div className="row">
																<div className="col-12 col-md-6 pr-md-0">
																		<div className="alert alert-danger mt-2 mb-0">{ this.renderBookingDetailErrorMessage() }</div>
																</div>
														</div>
	                      }

												{
	                          !this.state.errorMessage ? null :
														<div className="row">
																<div className="col-12 col-md-6 pr-md-0">
																		<div className="alert alert-danger mt-2 mb-0"><strong dangerouslySetInnerHTML={{__html: this.state.errorMessage}}></strong></div>
																</div>
														</div>
	                      }

	                      <button className="btn-md btn btn-success btn-square mt-4 col-12 col-md-2" id="checkout" onClick={ this.retrieveBooking.bind(this) }>
	                          RETRIEVE
	                      </button>
	                  </div>

                </div>
            </div>
        );
    }

		renderBookingDetailErrorMessage() {
				if ( this.state.bookingDetailErrorMessage ) {
						if ( Array.isArray(this.state.bookingDetailErrorMessage) ) {
								let count = 0;
								let messages = this.state.bookingDetailErrorMessage.map(i => {
										return <p key={count++}><strong dangerouslySetInnerHTML={{__html: i}}></strong></p>
								});

								return messages;
						}

						return <p><strong dangerouslySetInnerHTML={{__html: this.state.bookingDetailErrorMessage}}></strong></p>;
				}

				return null;
		}

    renderBookingDetail() {
				let latestCancellationDate = '';
				let latestModificationDate = '';
				let canCancel = true;
				let canModify = true;
				let dayDifference = 0;
				if ( this.state.bookingDetail.latest_cancellation_date ) {
						latestCancellationDate = moment(this.state.bookingDetail.latest_cancellation_date);
						dayDifference = moment().diff(latestCancellationDate, 'days');
						console.log("Cancellation Difference day");
						console.log(dayDifference + " " + latestCancellationDate.format("YYYY-MM-DD"));
						if ( dayDifference > 0 ) {
								canCancel = false;
						}
				}
				else {
						canCancel = false;
				}

				if ( this.state.bookingDetail.latest_modification_date ) {
						latestModificationDate = moment(this.state.bookingDetail.latest_modification_date);
						dayDifference = moment().diff(latestModificationDate, 'days');
						console.log("Modify Difference day");
						console.log(dayDifference + " " + latestModificationDate.format("YYYY-MM-DD"));
						if ( dayDifference > 0 ) {
								canModify = false;
						}
				}
				else {
						canModify = false;
				}

				if ( this.state.step == 2 ) {
						let cancellationPenalty = 0;
						let bbqPitForfeiture = this.state.addonBBQAmount + this.state.bookingDetail.bbq_pit_charge + this.state.bookingDetail.bbq_pit_tax;

						if ( canCancel ) {
								cancellationPenalty = parseFloat((this.state.bookingDetail.cancellation_admin_fee * this.state.bookingDetail.total_rooms).toFixed(2));
						}
						else {
								cancellationPenalty = parseFloat((this.state.bookingDetail.grand_total - bbqPitForfeiture).toFixed(2));
						}

						return(
		            <div className="booking-detail-container">
		                <BookingDetail bookingDetail={this.state.bookingDetail} addons={this.state.addons} isStatusCancelled={this.state.isStatusCancelled} isModify={ this.state.step == 3 && this.state.toModify } />

										{ (this.state.isNotPaidStatus && !this.state.isStatusCancelled) ?
										<div>
												<div style={{margin: '20px auto', textAlign: 'left'}}>
														<table border="0" style={{width: '100%'}}>
																<tbody>
																		<tr>
																				<th></th>
																				<th>
																						<div style={{paddingTop: '10px'}}>
																								<div style={{color: 'rgb(40, 85, 49)', fontSize: '18px', fontWeight: 'bold'}}>
																										Refund Details
																								</div>
																						</div>
																				</th>

																		</tr>
																</tbody>
														</table>
												</div>

												<div style={{textAlign: 'left', marginTop: '10px', marginBottom: '20px', padding: '20px 30px', backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 15px 20px'}}>
														<table border="0" style={{width: '100%', paddingTop: '25px'}}>
																<tbody>
																		<tr>
																				<th>
																						<div style={{fontSize: '14px', fontWeight: 'normal'}}>Deposit Received</div>
																				</th>
																				<th>
																						<div style={{fontSize: '14px', textAlign: 'right'}}>S${ _formatDecimalAmount(this.state.bookingDetail.grand_total) }</div>
																				</th>
																		</tr>

																		<tr>
																				<th>
																						<div style={{fontSize: '14px', fontWeight: 'normal'}}>
																								Cancellation and/or Admin Fee
																						</div>
																				</th>

																				<th>
																						<div style={{fontSize: '14px', textAlign: 'right'}}>
																								(S${ _formatDecimalAmount(cancellationPenalty) })
																						</div>
																				</th>
																		</tr>

																		<tr>
																				<th>
																						<div style={{fontSize: '14px', fontWeight: 'normal'}}>
																								BBQ PIT Forfeiture
																						</div>
																				</th>

																				<th>
																						<div style={{fontSize: '14px', textAlign: 'right'}}>
																								(S${ _formatDecimalAmount(bbqPitForfeiture) })
																						</div>
																				</th>
																		</tr>
																</tbody>
														</table>

														<div style={{borderBottom: '3px solid rgb(225, 225, 225)', paddingTop: '15px'}}></div>

														<table border="0" style={{width: '100%', marginTop: '15px'}}>
																<tbody>

																		<tr>
																				<th>
																						<div style={{fontSize: '14px', fontWeight: 'normal'}}>Refund to original form of payment</div>
																				</th>
																				<th>
																						<div style={{fontSize: '14px', textAlign: 'right'}}>
																								S${ _formatDecimalAmount(this.state.bookingDetail.grand_total - cancellationPenalty - bbqPitForfeiture) }
																						</div>
																				</th>
																		</tr>

																</tbody>
														</table>
												</div>
										</div> : null }

										{ this.state.isStatusCancelled ? null :
										<div className="row mt-4 pt-4">
												{/* <div className="col-12 col-md-6">
														<fieldset>
																<legend>Pre-Register</legend>
																<p>Fill in your information to fasten your check-in process.</p>
																<button className="btn-md btn btn-success btn-square mt-4 mt-md-2 col-12 col-md-4" id="checkout" onClick={ () => { this.setState({toPreRegister: true, step: 3}); window.scrollTo(0, 0); } }>
																		PRE-REGISTER
																</button>
														</fieldset>
												</div>*/ }

												<div className="col-12 col-md-6">
														<fieldset>
																<legend>Change / Cancel</legend>

																<p>Change or cancel your booking according to the reservation policies.</p>

																{ (!this.state.isNotPaidStatus && !canModify) ?
										              <div className="row">
										                <div className="col-12">
																		 	<div className="alert alert-info"><strong>Change booking is not allowed outside booking policy</strong>. The allowed period to change the booking has past.</div>
																		</div>
										              </div>
																	:
																	null }

								                <div className="row">
								                    <div className="col-12">
																				{ !this.state.isNotPaidStatus ?
								                        <div className="form-group mb-0">
								                            { canModify ?
								                            <button className="btn-md btn btn-success btn-square mt-4 mt-md-2 col-12 col-md-4" id="checkout" onClick={ () => { this.setState({toModify: true, step: 3}); window.scrollTo(0, 0); } }>
								                                CHANGE BOOKING
								                            </button> :
																						<button className="btn-md btn btn-success btn-square mt-4 mt-md-2 col-12 col-md-4" id="checkout" disabled>
								                                CHANGE BOOKING
								                            </button>
																						}


																						{ canCancel ?
								                            <button className="btn-md btn btn-danger btn-square mt-4 mt-md-2 col-12 col-md-4 ml-md-4" id="checkout" onClick={ () => { this.setState({toCancel: true, step: 3}); window.scrollTo(0, 0); } }>
								                                CANCEL BOOKING
								                            </button> :
																						<button className="btn-md btn btn-danger btn-square mt-4 mt-md-2 col-12 col-md-4 ml-md-4" id="checkout" disabled>
								                                CANCEL BOOKING
								                            </button>
																					  }
								                        </div> : null }
								                    </div>
								                </div>
														</fieldset>
												</div>
										</div> }
		            </div>
		        );
				}
				// pre-register
				else if ( this.state.step == 3 && this.state.toPreRegister ) {
						return(
								<PreRegister backToBookingInformation={this.backToBookingInformation.bind(this)}
										bookingDetail={this.state.bookingDetail}
										countries={this.state.countries}
										nationalities={this.state.nationalities}
										updateToken={this.state.bookingDetail.update_token} />
						);
				}
				// modify or cancel booking
				else if ( this.state.step == 3 ) {
						let totalNights = 0;
						let checkInDate = '';
						let checkOutDate = '';
						let bbqDates = '';
						let chargeBreakdown = '';
						let refundSubTotal = 0;
						let cancellationPenalty = 0;
						let bbqPitForfeiture = 0;
						let policy = '';
						let specialRequest = '';
						let roomPreferences = '';

						checkInDate = moment(this.state.bookingDetail.check_in_date);
						checkOutDate = moment(this.state.bookingDetail.check_out_date);

						totalNights = checkOutDate.diff(checkInDate, 'day');

						if ( this.state.bookingDetail.bbq_pit_dates !== null && this.state.bookingDetail.bbq_pit_dates !== "" ) {
								if ( this.state.bookingDetail.bbq_pit_dates.indexOf(",") > -1 ) {
										let bbqDatesCount = 1;
										bbqDates = this.state.bookingDetail.bbq_pit_dates.split(',').map(i => {
												return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}} key={bbqDatesCount++}>{i}</p>
										});
								}
								else {
										bbqDates = this.state.bookingDetail.bbq_pit_dates;
								}
						}

						chargeBreakdown = <table className=""><tbody>{ this.loopChargeBreakdown(this.state.bookingDetail.charge_breakdown) }</tbody></table>;

						// Refund Details
						bbqPitForfeiture = this.state.addonBBQAmount + this.state.bookingDetail.bbq_pit_charge + this.state.bookingDetail.bbq_pit_tax;
						if ( canCancel ) {
								cancellationPenalty = parseFloat((this.state.bookingDetail.cancellation_admin_fee * this.state.bookingDetail.total_rooms).toFixed(2));
						}
						else {
								cancellationPenalty = parseFloat((this.state.bookingDetail.grand_total - bbqPitForfeiture).toFixed(2));
						}

						let policyCount = 1;
		        policy = this.state.bookingDetail.policy.split('\n').map(i => {
		            return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}} key={policyCount++}>{i}</p>
		        });

						if ( this.state.bookingDetail.special_request && this.state.bookingDetail.special_request != "" ) {
		            let specialRequestCount = 1;

								if ( Array.isArray(this.state.bookingDetail.special_request) ) {
										specialRequest = this.state.bookingDetail.special_request.map(i => {
												return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px', wordBreak: 'break-word'}} key={specialRequestCount++}>{i}</p>
										});
								}
								else {
			            	specialRequest = this.state.bookingDetail.special_request.split('\n').map(i => {
			                	return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px', wordBreak: 'break-word'}} key={specialRequestCount++}>{i}</p>
			            	});
								}
		        }

		        if ( this.state.selectedRoomPreferences.length > 0 ) {
								let roomPreferencesCount = 1;

								roomPreferences = this.state.selectedRoomPreferences.map(i => {
		                return <p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}} key={roomPreferencesCount++}>{i}</p>
		            });
		        }

						const cCanCancel = canCancel;

						return (
								<div className="booking-detail-container">
										<div className="booking-detail">
												<div style={{fontSize:'22px', position: 'relative'}}>
														Your Booking ID: { this.state.bookingDetail.id }

														{ /*this.state.step == 3 && this.state.toModify  ?
														<div className="edit-button-container">
																<button className="btn-md btn btn-success btn-square" data-toggle="modal" data-target="#update-guest-modal" id="update-guest">Update Guest Details</button>
														</div>
														: null*/ }
												</div>

												<div style={{margin:'30px auto', fontSize:'14px', fontWeight:'bold'}}>
													{ this.state.isGuestDetailsModified ?
													<div className="row">
														<div className="col-12">
															<div className="alert alert-danger">You have made changes to the Guest Details. Please process the modification below.</div>
														</div>
													</div> : null }

													<table style={{width:'100%'}} border="0">
														<tbody>
															<tr>
																<th style={{textAlign:'left'}}>Guest Name:</th>
																<th style={{textAlign:'right'}}>{ this.state.guestNameUpdate }</th>
															</tr>
															<tr>
																<th style={{textAlign:'left'}}>Reservations:</th>
																<th style={{textAlign:'right'}}>{ this.state.bookingDetail.total_rooms } Room{ this.state.bookingDetail.total_rooms > 1 ? 's' : null }, { totalNights } Night{ totalNights > 1 ? 's' : null }</th>
															</tr>
															<tr>
																<th style={{textAlign:'left'}}>Occupancy Per Room:</th>
																<th style={{textAlign:'right'}}>
																	{ this.state.bookingDetail.total_adults } Adult{this.state.bookingDetail.total_adults > 1 ? 's' : null}
																	{ this.state.bookingDetail.total_children > 0 ? ', ' + this.state.bookingDetail.total_children + 'children' : null }
																</th>
															</tr>
															<tr>
																<th style={{textAlign:'left'}}>Check in:</th>
																<th style={{textAlign:'right'}}>{ checkInDate.format('DD MMM YYYY') }</th>
															</tr>
															 <tr>
																<th style={{textAlign:'left'}}>Check out:</th>
																<th style={{textAlign:'right'}}>{ checkOutDate.format('DD MMM YYYY') }</th>
															</tr>
															 {/*<tr>
																<th style={{textAlign:'left'}}>Booking ID:</th>
																<th style={{textAlign:'right'}}>{ this.state.bookingID }</th>
															</tr>*/}
														</tbody>
													</table>
												</div>

												{ this.state.toCancel ?
												<div style={{margin:'20px auto', textAlign:'left'}}>

				                  <table style={{width:'100%'}} border="0">
				                    <tbody>
				                     <tr>
				                      <th>

				                      </th>
				                      <th>
				                        <div style={{paddingTop:'10px'}}>
				                          <div style={{color:'#285531',fontSize:'18px', fontWeight:'bold'}}>
				                              Reservation Policies
				                          </div>
				                        </div>
				                      </th>
				                     </tr>
				                    </tbody>
				                  </table>

				                </div> : null }

												{ this.state.toCancel ?
												<div style={{textAlign:'left',marginTop:'10px',marginBottom:'20px', padding:'20px 30px  20px 30px', backgroundColor:'#fff',boxShadow: '0 15px 20px rgba(0, 0, 0, 0.3)' }}>

				                  <div style={{fontWeight:'bold',fontSize:'16px'}}>
				                      Room Cancellation Policy
				                    </div>

				                  <div style={{paddingTop:'15px', paddingBottom: '10px',fontSize:'14px'}}>
				                      { policy }
				                  </div>

				                  <div style={{fontWeight:'bold',fontSize:'16px',paddingTop: '15px'}}>
				                      BBQ Pit Cancellation Policy
				                    </div>

				                  <div style={{paddingTop:'15px', paddingBottom: '10px',fontSize:'14px'}}>
				                    In the event of cancellation or inclement weather, 100% of BBQ pit booking charges are strictly non-refundable.
				                  </div>

				                  <div style={{fontWeight:'bold',fontSize:'16px',paddingTop: '15px'}}>
				                      No-Show/Early Check Out
				                    </div>

				                  <div style={{paddingTop:'15px', fontSize:'14px'}}>
				                    In the event of no-show, early departure or if guests fail to check-in on their stipulated date of arrival, 100% of room charges are strictly non-refundable.
				                  </div>

				                </div> : null }

												<BookingDetailRoom bookingDetail={this.state.bookingDetail} addons={this.state.addons} isModify={ this.state.step == 3 && this.state.toModify } />

												{ this.state.step == 3 && this.state.toModify ?
													<div className="show-mobile">
														<button className="btn-md btn btn-success btn-square mt-2 col-12" id="amend-room-booking" onClick={ this.handleUpdateRoom }>
																Change Room Booking
														</button>
													</div> : null
												/*<div className="row pt-3">
														<div className="col-xs-12 col-md-6">
																<fieldset>
																		<legend>Amend Room Booking</legend>
																		<p className="fieldset-2line">Amend your room booking including booking request(s) and addon such as bbq pit, www ticket, etc.</p>
																		<button className="btn-md btn btn-success btn-square mt-2 col-12 col-md-4" id="amend-room-booking" onClick={ this.handleUpdateRoom }>
																				Amend
																		</button>
																</fieldset>
														</div>

														<div className="col-xs-12 col-md-6">
																<fieldset>
																		<legend>Amend Addon</legend>
																		<p className="fieldset-2line">Amend your booking's addon such as bbq pit, www ticket, etc.</p>
																		<button className="btn-md btn btn-success btn-square mt-2 col-12 col-md-4" id="amend-add-on" onClick={ this.handleUpdateAddon }>
																				Amend
																		</button>
																</fieldset>
														</div>
												</div>*/ }
										</div>

										{ this.state.toModify ?
											<div>
													<div style={{margin:'20px auto', textAlign:'left'}}>

														<table style={{width:'100%'}} border="0">
															<tbody>
															 <tr>
																<th>

																</th>
																<th>
																	<div style={{paddingTop:'10px'}}>
																		<div style={{color:'#285531',fontSize:'18px', fontWeight:'bold', position: 'relative'}}>
																				Booking Request

																				{ this.state.step == 3 && this.state.toModify ?
																				<div className="edit-button-container">
																						<button className="btn-md btn btn-success btn-square" data-toggle="modal" data-target="#update-booking-request-modal" id="update-booking-request">Change Booking Request</button>
																				</div>
																				: null }
																		</div>
																	</div>
																</th>
															 </tr>
															</tbody>
														</table>

													</div>

													{ this.state.isBookingRequestModified ?
													<div className="row">
														<div className="col-12">
															<div className="alert alert-danger mt-2">You have made changes to the Booking Request. Please process the changes below.</div>
														</div>
													</div> : null }

													<div style={{textAlign:'left', marginTop:'10px', marginBottom:'20px', padding:'20px 30px  20px 30px', backgroundColor:'#fff',boxShadow: '0 15px 20px rgba(0, 0, 0, 0.3)'}}>

														<table style={{width:'100%',marginTop:'10px'}} border="0">
															<tbody>
																<tr>
																	<td style={{ paddingBottom: '15px' }}>
																		<div style={{fontSize:'14px',float:'left',fontWeight:'normal',width:'100%', textAlign: 'left'}}>
																			<strong>Room Preferences:</strong>
																			<br />
																			{roomPreferences != "" ? roomPreferences : '-'}
																		</div>
																	</td>
																</tr>

																<tr>
																	<td style={{ paddingBottom: '15px' }}>
																		<div style={{fontSize:'14px',float:'left',fontWeight:'normal',width:'100%', textAlign: 'left'}}>
																			<strong>Bed Preferences:</strong>
																			<br />
																			<p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}}>
																			{ this.state.bedPreferenceDisplay ? this.state.bedPreferenceDisplay : '-'}
																			</p>
																		</div>
																	</td>
																</tr>

																<tr>
																	<td style={{ paddingBottom: '15px' }}>
																		<div style={{fontSize:'14px',float:'left',fontWeight:'normal',width:'100%', textAlign: 'left'}}>
																			<strong>Estimated Time of Arrival:</strong>
																			<br />
																			{this.state.timeOfArrival !== "" ?
																				<p style={{textAlign: 'left', marginBottom: '0', fontSize: '14px'}}>{this.state.timeOfArrival}</p> : '-'}
																		</div>
																	</td>
																</tr>

																<tr>
																	<td style={{ paddingBottom: '15px' }}>
																		<div style={{fontSize:'14px',float:'left',fontWeight:'normal',width:'100%', textAlign: 'left'}}>
																			<strong>Special Request:</strong>
																			<br />
																			{ (this.state.comments && this.state.comments.length > 0) ? this.state.comments : '-'}
																		</div>
																	</td>
																</tr>


																<tr>
																	<td style={{ fontSize: '14px', fontStyle: 'italic' }}>
																			While we try our best to accommodate every request whenever possible, we seek your understanding that special requests are not guaranteed and may incur additional charges.
																	</td>
																</tr>
															</tbody>
														</table>

													</div>

													{ this.state.step == 3 && this.state.toModify ?
														<div className="show-mobile">
															<button className="btn-md btn btn-success btn-square mt-2 col-12" data-toggle="modal" data-target="#update-booking-request-modal" id="update-booking-request-mobile">Change Booking Request</button>
														</div> : null
													}

													{ /*<div className="row pt-3">
															<div className="col-xs-12 col-md-6">
																	<fieldset>
																			<legend>Amend Booking Request</legend>
																			<p>Amend your booking's booking request(s).</p>
																			<button className="btn-md btn btn-success btn-square mt-2 col-12 col-md-4" data-toggle="modal" data-target="#update-booking-request-modal" id="update-booking-request">
																					Amend
																			</button>
																	</fieldset>
															</div>
													</div>*/ }
											</div>
										: null }

										{ this.state.toCancel ?
										<div style={{margin: '20px auto', textAlign: 'left'}}>
												<table border="0" style={{width: '100%'}}>
														<tbody>
																<tr>
																		<th></th>
																		<th>
																				<div style={{paddingTop: '10px'}}>
																						<div style={{color: 'rgb(40, 85, 49)', fontSize: '18px', fontWeight: 'bold'}}>
																								Refund Details
																						</div>
																				</div>
																		</th>

																</tr>
														</tbody>
												</table>
										</div> : null }

										{ this.state.toCancel ?
										<div style={{textAlign: 'left', marginTop: '10px', marginBottom: '20px', padding: '20px 30px', backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 15px 20px'}}>
												<table border="0" style={{width: '100%', paddingTop: '25px'}}>
														<tbody>
																<tr>
																		<th>
																				<div style={{fontSize: '14px', fontWeight: 'normal'}}>Deposit Received</div>
																		</th>
																		<th>
																				<div style={{fontSize: '14px', textAlign: 'right'}}>S${ _formatDecimalAmount(this.state.bookingDetail.grand_total) }</div>
																		</th>
																</tr>

																<tr>
																		<th>
																				<div style={{fontSize: '14px', fontWeight: 'normal'}}>
																						Cancellation and/or Admin Fee
																				</div>
																		</th>

																		<th>
																				<div style={{fontSize: '14px', textAlign: 'right'}}>
																						(S${ _formatDecimalAmount(cancellationPenalty) })
																				</div>
																		</th>
																</tr>

																<tr>
																		<th>
																				<div style={{fontSize: '14px', fontWeight: 'normal'}}>
																						BBQ PIT Forfeiture
																				</div>
																		</th>

																		<th>
																				<div style={{fontSize: '14px', textAlign: 'right'}}>
																						(S${ _formatDecimalAmount(bbqPitForfeiture) })
																				</div>
																		</th>
																</tr>
														</tbody>
												</table>

												<div style={{borderBottom: '3px solid rgb(225, 225, 225)', paddingTop: '15px'}}></div>

												<table border="0" style={{width: '100%', marginTop: '15px'}}>
														<tbody>

																<tr>
																		<th>
																				<div style={{fontSize: '14px', fontWeight: 'normal'}}>Refund to original form of payment</div>
																		</th>
																		<th>
																				<div style={{fontSize: '14px', textAlign: 'right'}}>S${ _formatDecimalAmount(this.state.bookingDetail.grand_total - cancellationPenalty - bbqPitForfeiture) }</div>
																		</th>
																</tr>

														</tbody>
												</table>
										</div> : null }

										{ this.state.toCancel && !canCancel ?
												<div className="row">
													<div className="col-12">
														<div className="alert alert-info mt-4"><strong>Non-Refundable booking</strong>. Once the booking is cancelled, the deposit will be forfeited.</div>
													</div>
												</div>
											: null
										}

										{ this.state.toCancel && this.state.cancelErrorMessage != '' ?
												<div className="row">
													<div className="col-12">
														<div className="alert alert-danger mt-2"><strong>Cancellation Failed</strong>. { this.state.cancelErrorMessage }</div>
													</div>
												</div>
											: null
										}

										{	this.state.toCancel && this.state.isCancelled ?
											<div className="row">
												<div className="col-12">
													<div className="alert alert-success mt-4"><strong>Cancellation In-Progress</strong>. { !canCancel ? 'Your booking has been cancelled' : 'Your booking has been cancelled. Refund will be processed within 28 working days' }.</div>
												</div>
											</div>
											: null
										}

										<div className="row">
												<div className="col-12">
														<div className="form-group text-center text-md-left mt-4">
																{ this.state.toModify ?
																<button disabled={ this.state.isBookingRequestModified ? false : true } className="btn-md btn btn-success btn-square mt-4 mt-md-0 col-12 col-md-3" id="checkout" onClick={ this.handleProcessModification }>
																		PROCESS CHANGES
																</button> :
																null
																}

																{ this.state.toCancel && !this.state.isCancelled ?
																	<div className="cancellation-reason-container">
																			<h3>Cancellation Reason</h3>
																			<p>Please include reason of your booking cancellation.</p>
																			{/*<small className="text-success">All requests are subject to availability.</small>*/}

																			<p>
																					<select name="cancellationType" value={ this.state.cancellationType } className="form-control col-12 col-lg-6"
																							onChange={ this.setCancellationType.bind(this) }>
																						<option value="Personal reason, change of plan">Personal reason, change of plan</option>
																						<option value="Change of date">Change of date</option>
																						<option value="Duplicate booking, want to cancel the one I don’t need">Duplicate booking, want to cancel the one I don’t need</option>
																						<option value="Hotel facilities i.e Water Park, BBQ Pit not available">Hotel facilities i.e Water Park, BBQ Pit not available</option>
																						<option value="I found alternate accommodation">I found alternate accommodation</option>
																						<option value="Unable to travel due to pandemic restriction">Unable to travel due to pandemic restriction</option>
																						<option value="Other reason">Other reason</option>
																					</select>
																			</p>

																			{ this.state.cancellationType == 'Other reason' ?
																			<div className="cancellation-reason-text">
																					<textarea name="reason" className="special-request cancellation-reason" cols="30" rows="5"
																							value={ this.state.cancellationReason }
																							onChange={ (event) => { this.setState({ cancellationReason: event.target.value }) } }
																							maxLength="200"></textarea>
																					<p className="text-area-counter"> Max. 200 characters </p>
																			</div> : null }
																	</div>
																	: null
																}

																{	this.state.toCancel && this.state.isCancelled ?
																	<NavLink to="/"  href={null} className="link-back active">
																			BACK TO BOOKING PAGE
																	</NavLink>
																	: null
																}

																{ this.state.toCancel && !this.state.isCancelled ?
																<button className="btn-md btn btn-danger btn-square mt-4 mt-md-0 col-12 col-md-3" id="checkout" onClick={ () => this.confirmCancellation(canCancel) }>
																		YES, CANCEL THE BOOKING
																</button> :
																null
																}

																{ this.state.toCancel && !this.state.isCancelled ?
																	<a style={{ textDecoration: 'underline', verticalAlign: 'top', marginBottom: '0px', marginTop: '15px' }} className="link-back active pl-md-4" onClick={ () => { this.setState({ step: 2, toCancel: false, toModify: false }); window.scrollTo(0, 0); } }>
																			NO, I DO NOT WANT TO CANCEL
																	</a>
																	: null
																}

																{ this.state.toModify && this.state.step == 3 ?
																	<a style={{ textDecoration: 'underline', verticalAlign: 'top', marginBottom: '0px', marginTop: '15px', color: '#f46032' }} className="link-back active pl-md-4 orange pt-2" onClick={ () => { this.setState({ step: 2, toCancel: false, toModify: false }); window.scrollTo(0, 0); } }>
																			NO, I DO NOT WANT TO CHANGE
																	</a>
																	: null
																}
														</div>
												</div>
										</div>
								</div>
						);
				}
    }

		renderAddons() {
        let addons = [];
        if ( this.state.addons.length > 0 ) {
            addons.push(
                <tr key="addon">
                    <td colSpan="2"><strong>Addon(s):</strong></td>
                </tr>
            )

            for ( let index in this.state.addons ) {
                let addon = this.state.addons[index];

                addons.push(
                    <tr key={index}>
                      <th>
                        <div style={{fontSize:'14px', fontWeight:'normal'}}>
                            { addon.name } ({ moment(addon.from).format('DD MMM YYYY') }{ addon.from != addon.to ? " - " + moment(addon.to).format('DD MMM YYYY') : null }) x { addon.qty }
                        </div>
                      </th>
                      <th>
                        <div style={{fontSize:'14px', textAlign:'right'}}>
                            S${ _formatDecimalAmount(addon.amount) }
                        </div>
                      </th>
                    </tr>
                );
            }
        }

        return addons;
    }

		loopArrivalTime = () => {
				let dataToReturn = [];
				let startTime = 15;
				let endTime = 6;
				let nextDayLimit = 24;
				let isStop = false;
				let isNextDay = false;
				let time = '';

				let count = 30;

				while ( !isStop || count === 0 ) {
						if ( startTime === (nextDayLimit - 1) ) {
								time = startTime.toString().padStart(2, '0') + ":00 - 00:00";
						}
						else {
								time = startTime.toString().padStart(2, '0') + ":00 - " + (startTime + 1).toString().padStart(2, '0') + ":00" + ( isNextDay ? " (The Next Day)" : "" );
						}

						if ( this.state.timeOfArrivalTemp == time ) {
								dataToReturn.push(
										<option selected key={ time } value={ time }>{ time }</option>
								);
						}
						else {
								dataToReturn.push(
										<option key={ time } value={ time }>{ time }</option>
								);
						}

						startTime++;

						if ( startTime === nextDayLimit ) {
								startTime = 0;
								isNextDay = true;
						}

						if ( startTime === endTime ) {
								isStop = true;
						}

						count--;
				}

				return dataToReturn;
		}

		loopRoomPreferences() {
				let preferences = [];
				let preference = '';

				for ( let specialRequestId in this.state.roomPreferences ) {
						preference = this.state.roomPreferences[specialRequestId];

						preferences.push(
								<div key={ specialRequestId } className="col-xs-12">
									<div className="form-check custom-checkbox">
										<input checked={ this.state.roomPreferencesTemp.indexOf(preference.code) > -1 } name={ preference.name } type="checkbox" value={ preference.code } id={preference.name} onChange={ this.handleRoomPreferences } />

										<span className="checkmark"></span>
										<label className="form-check-label" htmlFor={preference.name}>
												{ preference.name }
										</label>
									</div>
								</div>
						);
				}

				return preferences;
		}

		loopBedPreferences() {
				let preferences = [];
				let preference = '';
				let bedIcon = '';
				let preferenceName = '';
				let isTwin = false;
				let className = '';
				let bedPreferences = this.state.bedPreferences;

				for ( let specialRequestId in bedPreferences ) {
						preference = bedPreferences[specialRequestId];
						preferenceName = preference.name.toLowerCase();
						isTwin = false;

						bedIcon = <em className="bed-icon large-bed"></em>;
						if ( preferenceName.indexOf("single") > -1 ) {
								bedIcon = <em className="bed-icon single-bed"></em>;
						}
						else if ( preferenceName.indexOf("double") > -1 ) {
								bedIcon = <em className="bed-icon large-bed"></em>;
						}
						else if ( preferenceName.indexOf("twin") > false ) {
								bedIcon = <em className="bed-icon twin-bed"></em>;
								isTwin = true;
						}

						className = 'form-check form-check-inline check-bed' + ( isTwin ? ' twin' : '' );

						preferences.push(
								<div key={ specialRequestId } className={ className }>
										<input className="form-check-input" type="radio"
												name={ preference.name } id={ preference.name }
												checked={ this.state.bedPreference == preference.code }
												value={ preference.code } onChange={ this.handleBedPreferences } />
										<label className="form-check-label" htmlFor={ preference.name }>
										{ bedIcon } { preference.name.replace("Request", "").replace("for", "").trim() }</label>
								</div>
						);
				}

				return preferences;
		}

    renderBackButton() {
        if ( this.state.step == 2 ) {
						return(
                <a href={null} className="link-back active" onClick={ () => this.setState({ step: 1, bookingDetail: null, isNotPaidStatus: false }) }>&lt; Back</a>
            );
        }
				else if ( this.state.step == 3 ) {
						if ( this.state.toPreRegister ) {
								return(
										<a href={null} className="link-back active" onClick={ () => this.setState({ step: 2, toPreRegister: false }) }>&lt; Back</a>
								);
						}
						if ( !(this.state.toCancel && this.state.isCancelled) ) {
		            return(
		                <a href={null} className="link-back active" onClick={ () => this.setState({ step: 2, toCancel: false, toModify: false }) }>&lt; Back</a>
		            );
						}
        }

        return(
            <NavLink to="/" className="link-back">&lt; Back</NavLink>
        );
    }

		backToBookingInformation() {
				this.setState({ step: 2, toPreRegister: false });

				window.scrollTo(0, 0);
		}

    render(){
				return(
            <div>
								{ (this.state.isRetrieveBooking || this.state.isCancelling || this.state.isModifying) ? _loading() : null }

                <div id="book-step" className="no-banner">
										<div className="container">
												<div className="row">
														<div id="sort">
																<div className="btn-group position-relative" id="sort-list">
																		&nbsp;
																</div>
														</div>

														<div className={ "step " + (this.state.step == 1 ? "active" : "previous")} data-id="1">
																<span style={{paddingTop:2}} className="number-count">1</span>Retrieve Booking Details
														</div>

														{ this.state.isNotPaidStatus ?
														<div className={ "step " + (this.state.step == 2 ? "active" : this.state.step == 3 ? "previous" : "next")} data-id="2">
																<span style={{paddingTop:2}} className="number-count">2</span>Booking Information
														</div>
														:
														<div className={ "step " + (this.state.step == 2 ? "active" : this.state.step == 3 ? "previous" : "next")} data-id="2">
																<span style={{paddingTop:2}} className="number-count">2</span>{ /*Pre-Register or*/ } Change/Cancel Booking
														</div> }

														{ this.state.isNotPaidStatus ? <div className=""></div> :
														<div className={ "step " + (this.state.step == 3 ? "active" : "next")} data-id="3">
																<span style={{paddingTop:1}} className="number-count">3</span>Confirmation
														</div> }

														<div id="sort">
																<div className="btn-group position-relative" id="sort-list">
																		&nbsp;
																</div>
														</div>
												</div>
										</div>
								</div>

                <div id="main">
        					<div className="container">
        						<div className="row">
        							<div className="col-12" id="checkout">
                        { this.renderBackButton() }

        								{ /*this.state.errorMessage ?
        									<div className="alert alert-danger mt-2">{ this.errorMessage }</div>
        								: null*/ }

												{ this.state.isNotPaidStatus ?
												<div className="row">
													<div className="col-12">
														<div className="alert alert-info mt-4">Your booking status is <strong>{ this.state.bookingStatusText }</strong>.</div>
													</div>
												</div>
												: null }

        								<div className="checkout information">
        									<div className="row">
        										<div className="col-md-12">

															{ this.state.step == 1 ? <p>Need to make a change? Don’t worry, it’s quick and easy to change or cancel your booking online.</p> : null }

        											{ this.state.step == 1 || this.state.step == 2 ? <h3>Booking Information</h3> : null }
															{ this.state.step == 3 && this.state.toCancel ? <h3>Cancel Booking</h3> : null }
															{ this.state.step == 3 && this.state.toModify ? <h3>Change Booking</h3> : null }
															{ this.state.step == 3 && this.state.toPreRegister ? <h3>Guest Pre-Registration</h3> : null }

															{ this.state.step == 1 ? <p className="text-green">Please fill below fields to retrieve your booking details.</p> : null }
															{ this.state.step == 3 && this.state.toPreRegister ? <p className="text-green">Thank you for choosing D’Resort @ Downtown East for your upcoming stay. Kindly provide the following pre-registration particulars to accelerate the check-in process.</p> : null }
        											<hr />
        										</div>
        									</div>

                          { this.state.bookingDetail ? this.renderBookingDetail() : null }

                          { this.state.step == 1 ? this.renderRetrieveBookingForm() : null }

        								</div>
        							</div>
        						</div>
        					</div>
        				</div>

								{ /*<div className="modal fade popUpModel" id="update-guest-modal" tabIndex="-1" role="dialog" aria-hidden="true">
		                <div style={{maxWidth:450}} className="modal-dialog" role="document">
		                    <div className="modal-content">
		                        <div className="modal-body">
		                            <div className="modal-header">
		                                <h4 className="modal-title">Update Guest Details</h4>
		                                <button type="button" className="close" data-dismiss="modal">&#10005;</button>
		                            </div>

																<div className="form-group">
																	<label htmlFor="usr">Guest Name <span className="asterix">*</span></label>
																	<input name="guestNameUpdateTemp" type="text" className="form-control" value={ this.state.guestNameUpdateTemp } onBlur={ this.handleNameBlur } onChange={ this.handleData } placeholder="Full name as shown on Passport or NRIC" />
																	{
																		this.state.isGuestNameValid ? null :
																		<p style={{
																			marginTop: '7px',
																			color: 'red',
																			fontWeight: 'bold',
																		}}>Guest Name is Invalid. Guest Name can only contains alphabet, space, and special characters such as /, ', -, ., and (,).</p>
																	}
																</div>

																<div className="form-group">
																	<label htmlFor="usr">Email <span className="asterix">*</span></label>
																	<input name="guestEmailUpdate" type="email" className="form-control" value={ this.state.guestEmailUpdate } readOnly />
																</div>

																<div className="form-group mb-0">
																	<label htmlFor="usr">Mobile <span className="asterix">*</span></label>
																</div>
																<div className="form-group input-group form-mobile">
																	<div className="input-group-prepend">
																		<input pattern="/^\+(?:[\d]*)/g" type="text" name="guestIDDTemp" className="form-control" value={ this.state.guestIDDTemp } placeholder="+IDD Code" maxLength="4" onChange={ (e) => this.handleData(e,'IDD') } />
																	</div>
																	<input pattern="[0-9]*" type="text" name="guestMobileTemp" className="form-control" value={ this.state.guestMobileTemp } maxLength="12" onChange={ (e) => this.handleData(e,'mobile') } />
																</div>

																<div className="form-group">
																	<button className="btn-md btn btn-success btn-square" id="update-guest-action" onClick={ this.handleUpdateGuestDetails } data-dismiss="modal">Update</button>
																	<br />
																	<button className="btn-md btn btn-danger btn-square mt-3" id="update-guest-action-cancel" data-dismiss="modal">Cancel</button>
																</div>
														</div>
												</div>
										</div>
								</div>*/ }

								<div className="modal fade popUpModel" id="update-booking-request-modal" tabIndex="-1" role="dialog" aria-hidden="true">
		                <div style={{maxWidth:450}} className="modal-dialog" role="document">
		                    <div className="modal-content">
		                        <div className="modal-body">
		                            <div className="modal-header">
		                                <h4 className="modal-title">Change Booking Request</h4>
		                                <button type="button" className="close" data-dismiss="modal">&#10005;</button>
		                            </div>

																<div className="form-group mt-2">
																	<p className="title mb-0">Room Preferences</p>
																	{ this.loopRoomPreferences() }
																</div>

																{ this.state.bedPreferences.length > 0 ?
																	<div className="form-group">
																		<p className="title mb-0">Bed Preferences</p>
																		{ this.loopBedPreferences() }
																	</div>
																	: null }

																<div className="form-group">
																	<p className="title mb-0">Estimated Time of Arrival</p>
																	<div className="addon-input">
																		<select className="custom-select" onChange={ this.setTimeOfArrival.bind(this) }>
																			{ this.state.timeOfArrivalTemp == "I don't know" ?
																				<option value="I don't know" selected>I don't know</option>
																				: <option value="I don't know">I don't know</option>
																		 	}
																			{ this.loopArrivalTime() }
																		</select>
																		Check-in time after 3pm, check out before 11am.
																	</div>
																</div>

																<div className="form-group">
																	<p className="title mb-0">Special Request</p>
																	<p>Please include details of your special request. Special requests are not guaranteed and may incur additional charges.</p>
																	{/*<small className="text-success">All requests are subject to availability.</small>*/}
																	<textarea name="commentsTemp" className="special-request" cols="30" rows="5" defaultValue={ this.state.commentsTemp } onChange={ this.handleData } maxLength="200"></textarea>
																	<p className="text-area-counter"> Max. 200 characters </p>
																</div>

																{ /*<p style={{ fontSize: '14px', fontStyle: 'italic' }}>
																		While we try our best to accommodate every request whenever possible, we seek your understanding that special requests are not guaranteed and may incur additional charges.
																</p> */ }

																<div className="form-group">
																	<button className="btn-md btn btn-success btn-square" id="update-booking-request-action" onClick={ this.handleUpdateBookingRequest } data-dismiss="modal">Change</button>
																	<br />
																	<button className="btn-md btn btn-danger btn-square mt-3" id="update-booking-request-action-cancel" data-dismiss="modal">Cancel</button>
																</div>
														</div>
												</div>
										</div>
								</div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let params = { retrieveBookingStatus: false, data: null, cancelBookingStatus: false, cancelBookingData: null, modifyBookingStatus: false, modifyBookingData: null }

    if ( state.bookingDetail.result ) {
        params.retrieveBookingStatus = true;
        params.data = state.bookingDetail.result;
    }

		if ( state.cancelBooking.result ) {
				params.cancelBookingStatus = true;
				params.cancelBookingData = state.cancelBooking.result;
		}

		if ( state.modifyBooking.result ) {
				params.modifyBookingStatus = true;
				params.modifyBookingData = state.modifyBooking.result;
		}
    return params;
}

const mapDispatchToProps = (dispatch) => {
  const { retrieveBooking, cancelBooking, modifyBooking } = allAPI;
  return bindActionCreators({ retrieveBooking, cancelBooking, modifyBooking }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(ManageBooking));
