import React from 'react';
import $ from 'jquery';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as allAPI from '../../apis/all';
import * as config from '../../const/config';

class Main extends React.Component{
    componentDidMount() {
        window.scrollTo(0, 0);
        $("#login").hide();

        localStorage.removeItem(config.KEY_ADDONS);
				localStorage.removeItem(config.KEY_TOTAL_PACKAGES_AMOUNT);
        localStorage.removeItem(config.KEY_TOTAL_PACKAGE_TAX_AND_FEES);
        localStorage.removeItem(config.KEY_SELECTED_ADDON);

				localStorage.removeItem(config.KEY_PAYMENT_TOTAL);
				localStorage.removeItem(config.KEY_PAYMENT_UNIQUE_ID);

        let cardNo = localStorage.getItem(config.KEY_CARD_NO);
        if ( cardNo !== null ) {
            this.props.login(cardNo);
        }
    }

    render(){
        return(
            <div className="thanks no-banner cancel-payment">
                <div className="thankyou-container">
                    <div style={{fontSize:'14px',fontWeight:'bold'}}>
                        Payment is unsuccessful due to the following
                    </div>
                    <div style={{fontSize: '12px',marginTop:'10px', textAlign: 'left', fontWeight: 'normal'}}>
                        { this.props.match.params.message ? decodeURIComponent((this.props.match.params.message + '').replace(/\+/g, '%20')) : '' }
                    </div>
                    <div style={{marginTop:15}} className="text-center">
                        <button class="btn btn-sm btn-success btn-square book" onClick={() => { this.props.history.push('/') }}>Back to Booking Page</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let params = { loginReturn: null }

    if ( state.login.result ) {
        params.loginReturn = state.login.result;
    }

    return params;
}

const mapDispatchToProps = (dispatch) => {
  const { login } = allAPI;
  return bindActionCreators({ login }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(Main));
