import React from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImgWithTooltip, isMobileView } from '../../misc/helper_functions';
import { _getLocalStorage } from '../../functions/helper_functions';
import { _loading } from '../../functions/message_functions';
import $ from 'jquery';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as allAPI from '../../apis/all';
import * as config from '../../const/config';

import MyAssets from '../../assets';
import BackgroundImage from '../../assets/img/stay-banner2.png';

import LightboxComponent from '../../component/LightboxComponent';

const COUNT_PERSON = {
    PLUS:"PLUS",
    MIN:"MIN",
    ADULT:"ADULT",
    CHILDREN:"CHILDREN"
}
const DATE_NOW_STRING = moment().format('DD MMM YYYY');

var contactFirstOffSet = null;

class Rate extends React.Component{
    constructor(props){
        super(props);

        let checkInDate = _getLocalStorage(config.KEY_CHECK_IN_DATE);
        let checkOutDate = _getLocalStorage(config.KEY_CHECK_OUT_DATE);
        let totalNight = _getLocalStorage(config.KEY_TOTAL_NIGHT);
    		let totalRoom = _getLocalStorage(config.KEY_TOTAL_ROOMS);
    		let totalAdult = _getLocalStorage(config.KEY_TOTAL_ADULT);
    		let totalChildren = _getLocalStorage(config.KEY_TOTAL_CHILDREN);
    		let specialCode = _getLocalStorage(config.KEY_SPECIAL_CODE);
    		let specialCodeType = _getLocalStorage(config.KEY_SPECIAL_CODE_TYPE);console.log("specialCode="+specialCode);console.log("specialCodeType="+specialCodeType);

    		// check whether check in date is after today
    		if ( moment(checkInDate) < moment() ) {
    			checkInDate = moment().format('YYYY-MM-DD');
    			checkOutDate = moment().add(1, 'day').format('YYYY-MM-DD');
    			totalNight = 1;
    		}

      	this.state={
      			currentBookedTotal:1,
      			totalAdult: totalAdult != null ? parseInt(totalAdult) : 1,
      			totalChildren: totalChildren != null ? parseInt(totalChildren) : 0,
      			specialCodeType: specialCodeType != null ? specialCodeType : '',
      			specialCode: specialCode != null ? specialCode : '',
      			isShowAll: false,
      			rates: null,
      			isSearch: false,
      			checkInDate: checkInDate ? moment(checkInDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      			checkOutDate: checkOutDate ? moment(checkOutDate).format('YYYY-MM-DD') : moment().add(1, 'day').format('YYYY-MM-DD'),
      			startDateString: checkInDate ? moment(checkInDate).format('DD MMM YYYY') : moment().format('DD MMM YYYY'),
      			endDateString: checkOutDate ? moment(checkOutDate).format('DD MMM YYYY') : moment().add(1,'day').format('DD MMM YYYY'),
      			persons: totalAdult != null ? `${totalRoom} Room${totalRoom>1?'s':''}, ${totalAdult} Adult${totalAdult>1?'s':''}, ${totalChildren} Child${totalChildren<=1?'':'ren'}` : '1 Room, 1 Adult, 0 Children',
      			totalNight: totalNight ? totalNight : 1,
      			totalRooms: totalRoom != null ? parseInt(totalRoom) : 1,
      			totalAPIRates: 0,
      			roomName: '',
      			roomCode: '',
      			ratePlanCode: '',
      			isProcessBooking: false,
      			specialRequests: [],
      			isMember: false,
      			/* specialCode: '',
      			specialCodeType: '', */
      			specialCodeText: specialCodeType != null && specialCodeType != "" ? specialCodeType + ", " + specialCode : '',
      			startDateRangePickerValue: checkInDate ? moment(checkInDate) : moment(),
      			endDateRangePickerValue: checkOutDate ? moment(checkOutDate) : moment().add(1,'day'),
      	}
    }
    componentDidMount(){
		    window.scrollTo(0, 0);
      	this.searchRate();
    }

    removeReservationDetail = (target) => {
      $(`.data-book-${target}`).remove();
    }

    handleSpecialCodeType(event) {
        if ( event.target.value === 'Select Code Type' ) {
            this.setState({ specialCodeType: '', specialCodeText: '' });
        }
        else {
            this.setState({ specialCodeType: event.target.value, specialCodeText: event.target.value + ", " + this.state.specialCode });
        }
    }

    handleSpecialCode(event) {
        this.setState({ specialCode: event.target.value, specialCodeText: this.state.specialCodeType + ", " + event.target.value });
    }

    addReservation = (roomCode, ratePlanCode, specialRequests, roomName) => {
        this.props.roomRateDetails(this.state.checkInDate, this.state.checkOutDate, this.state.totalRooms, this.state.totalAdult, this.state.totalChildren,
            ratePlanCode, roomCode);

        this.setState({ ratePlanCode: ratePlanCode, roomCode: roomCode, roomName: roomName, specialRequests: specialRequests, isProcessBooking: true });
    }

    handleClick = (event) => {
          if(!event.target.className || !event.target.className.includes || !event.target.className.includes('count-person-pop-up')){
              this.showHideCountPerson(false);
          }
          if(!event.target.className || !event.target.className.includes || !event.target.className.includes('spesial-code-pop-up')){
              this.showHideSpesialCode(false);
          }
          if(!event.target.className || !event.target.className.includes || !event.target.className.includes('dropdown-toggle')){
              $('.dropdown-menu').removeClass('show');
          }
      }

      searchRate = () => {
          localStorage.setItem(config.KEY_CHECK_IN_DATE, this.state.checkInDate);
          localStorage.setItem(config.KEY_CHECK_OUT_DATE, this.state.checkOutDate);
          localStorage.setItem(config.KEY_TOTAL_NIGHT, this.state.totalNight);
    			localStorage.setItem(config.KEY_TOTAL_ROOMS, this.state.totalRooms);
    			localStorage.setItem(config.KEY_TOTAL_ADULT, this.state.totalAdult);
    			localStorage.setItem(config.KEY_TOTAL_CHILDREN, this.state.totalChildren);
    			localStorage.setItem(config.KEY_SPECIAL_CODE, this.state.specialCode);
    			localStorage.setItem(config.KEY_SPECIAL_CODE_TYPE, this.state.specialCodeType);

          this.props.searchRates(this.state.checkInDate, this.state.checkOutDate, this.state.totalRooms, this.state.totalAdult, this.state.totalChildren,
              this.state.specialCodeType, this.state.specialCode);

          this.setState({ isSearch: true, totalAPIRates: 0 });
      }
    countPersonStyle = () => {
          let person = $('#person-input-container');
          // let parent = $('.banner-slider').height();
          if(person.length){
              return {
                  left: isMobileView()?(-person.offset().left + 20):'0px',
                  width:'400px'
              }
          }
      }
      showHideCountPerson = (status) => {
          status ? $('#count_person').show() : $('#count_person').hide();
      }
      showHideSpesialCode = (status) => {
          status ? $('#popup-specialcode').show() : $('#popup-specialcode').hide();
    }
    checkCurrentRooms = () => {
      return $('.input-spin').length;
    }
    handleDeleteRoom = (target) => {
      $(`#${target}`).remove();
      this.setPersonCount();
      this.forceUpdate();
      this.setState({totalRooms:this.state.totalRooms - 1});
    }
      NewRoom = ({currentNumber}) => {
          return(
              <div className={`row input-spin count-person-pop-up`} id={`input-spin-${currentNumber}`}>
                  <div className="col-3 col-lg-3 count-person-pop-up">
                      <span className="room count-person-pop-up room-title">Room {currentNumber}</span>
                  </div>
                  <div className="col-4 col-lg-4 count-person-pop-up">
                      <div className="input-group input-group-sm count-person-pop-up">
                          <div className="input-group-prepend count-person-pop-up">
                              <button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-adults-${currentNumber}`,`input-children-${currentNumber}`)} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
                          </div>
                          <input onChange={()=>{}} defaultValue='1' id={`input-adults-${currentNumber}`} className="form-control input-adults count-person-pop-up" type="text" style={{textAlign:'center'}}/>
                          <div className="input-group-append count-person-pop-up">
                              <button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-adults-${currentNumber}`,`input-children-${currentNumber}`)} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
                          </div>
                      </div>
                  </div>
                  <div className="col-4 col-lg-4 count-person-pop-up">
                      <div className="input-group input-group-sm count-person-pop-up">
                          <div className="input-group-prepend count-person-pop-up">
                              <button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-children-${currentNumber}`,`input-adults-${currentNumber}`)} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
                          </div>
                          <input onChange={()=>{}} id={`input-children-${currentNumber}`} defaultValue='' placeholder='0' className="form-control input-children count-person-pop-up" type="text" style={{textAlign:'center'}}/>
                          <div className="input-group-append count-person-pop-up">
                              <button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-children-${currentNumber}`,`input-adults-${currentNumber}`)} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
                          </div>
                      </div>
                  </div>
          <div className='col-1 room-delete-btn col-lg-1 count-person-pop-up'>
            <div onClick={()=>this.handleDeleteRoom(`input-spin-${currentNumber}`)} className='count-person-pop-up' style={{width:20,height:20,borderRadius:10,borderColor: 'red',fontWeight:'bold',color:'red',marginTop:8,position:'relative',left:-4,cursor:'pointer'}}>X</div>
          </div>
              </div>
          )
      }
      addNewRoom = () => {
          const currentNumber = this.state.totalRooms+1;
          let parent = document.getElementById('row-room');
          let newDiv = document.createElement('div');
          ReactDOM.render(<this.NewRoom currentNumber={currentNumber}/>, newDiv);
          parent.appendChild(newDiv);
          this.setState({totalRooms:currentNumber},this.setPersonCount);
      }
      setPersonCount = () => {
      let adults = 0,children = 0, resultString = '';
          $('.input-adults').each((index,element)=>{
              adults += element.value ? parseInt(element.value) : 0;
          });
          $('.input-children').each((index,element)=>{
              children += element.value ? parseInt(element.value) : 0;
          });

          this.setState({ totalAdult: adults, totalChildren: children });
          //${adults>1?'s':''}
          // if(adults){
          //     resultString += `${adults} Adult `;
          // }
          // if(children){
          //     resultString += `${children} Children`;
      // }
      let totalRooms = 0;
		$('.input-room').each((index,element)=>{
            totalRooms += element.value ? parseInt(element.value) : 0;
        });
      resultString = `${totalRooms} Room${totalRooms>1?'s':''}, ${adults} Adult${adults>1?'s':''}, ${children} Child${children<=1?'':'ren'}`;
      // $('#persons').val(resultString);
      this.setState({persons:resultString, totalRooms: totalRooms});
    }
    checkCurrentChildrenCount = () => {
      let children = 0;
      $('.input-children').each((index,element)=>{
              children += element.value ? parseInt(element.value) : 0;
      });
      return children;
    }

	handleRoomChanged = (type,index) => {
		let target = $(`#${"input-room-" + index}`);
		let room = target.val() ? parseInt(target.val()) : 0;

		if(type === COUNT_PERSON.PLUS){
            if((room < config.MAX_ROOM)){
                target.val(room+1);

				/* $("#input-adults-" + index).val("1");
				$("#input-children-" + index).val("0"); */

				this.setPersonCount();
            }
        }else if(type === COUNT_PERSON.MIN){
			if(room <= 1){
				target.val('1');
			}else if(room){
				target.val(room-1);

				/* $("#input-adults-" + index).val("1");
				$("#input-children-" + index).val("0"); */

				this.setPersonCount();
			}
		}



	}

      handleCountPerson = (type,targetId,neighborId) => {
      let target = $(`#${targetId}`);
      let neighbor = $(`#${neighborId}`);

		let roomId = targetId.split("-");
		let room = $(`#${"input-room-" + roomId[2]}`);
      let situation = target.val() ? parseInt(target.val()) : 0;
      let situationNeighbor = neighbor.val() ? parseInt(neighbor.val()) : 0;
		let situationRoom = room.val() ? parseInt(room.val()) : 0;
          if(type === COUNT_PERSON.PLUS){
              /* if(situation!==100 && (situation+situationNeighbor < (situationRoom * config.MAX_ADULT_CHILDREN))){ */
              if(situation!==100 && (situation+situationNeighbor < config.MAX_ADULT_CHILDREN)){
                  target.val(situation+1);
              }
          }else if(type === COUNT_PERSON.MIN){
        if(targetId.includes('adults')){
          if(situation <= 1){
            target.val('1');
          }else if(situation){
            target.val(situation-1);
          }
        }else{
          if(situation <= 1){
            target.val('0');
          }else if(situation){
            target.val(situation-1);
          }
        }
      }
      this.setPersonCount();
          this.forceUpdate(()=>{console.log('already doing forceUpdate')});
      }
      toggleDropDown = () => {
          $('.dropdown-menu').addClass('show')
      }
      handleDateRangePickerApply = (event, picker) => {
          let { startDate, endDate } = picker;
          let startDateString = startDate.format('DD MMM YYYY');
          let endDateString = endDate.format('DD MMM YYYY');
          let totalNight = endDate.diff(startDate,'day');
          this.setState({startDateString,endDateString,totalNight, checkInDate: startDate.format('YYYY-MM-DD'), checkOutDate: endDate.format('YYYY-MM-DD'), startDateRangePickerValue: moment(startDate), endDateRangePickerValue: moment(endDate)});
      }
    openDetail = (target) => {
      if(isMobileView()){
        let clickedTarget = this.state[target];
        let jqueryTarget = `.${target}`;
        clickedTarget ? $(jqueryTarget).hide() : $(jqueryTarget).show();
        this.setState({[target]:!clickedTarget});
      }
    }

    setShowAll = () => { this.setState({ isShowAll: true }); $("#show_more").hide(); }

    loopRatePackages = (packages) => {
        let dataToReturn = [];
        for ( let packageCode in packages ) {
            let pack = packages[packageCode];

            dataToReturn.push(
                <div key={packageCode} className="text-green">{ pack.name } Included</div>
            );
        }

        return dataToReturn;
    }

    loopRateData = (roomCode, rates, specialRequests, roomName) => {
        let dataToReturn = [];
        let descriptionCount = 1;
        for ( let rateCode in rates ) {
            let rate = rates[rateCode];

            let description = rate.description.split('\n').map(i => {
                return <p key={descriptionCount++}>{i}</p>
            });

            let policyCancellationDate = '';
            if ( rate.policy ) {
                if ( rate.policy.cancellation_days > 0 ) {
                    policyCancellationDate = moment(this.state.checkInDate).add(-1 * rate.policy.cancellation_days, 'days').format('ddd, DD MMM');
                }
            }

            let lowestRate = 100000;
            dataToReturn.push(
              <div key={rateCode} className="row detail">
                <div className="col-md-7">
                  <div className="plan">
                    <h5 className="text-primary mb-0 plan_name"><strong>{ rate.name }</strong>
                      <div className="d-inline-block text-grey show-mobile plan-info">
                        <FontAwesomeIcon icon="info-circle"/>
                      </div>
                    </h5>
                    { rate.isMemberOnly ? <div className="options"><small className="text-primary"><strong>(Member Only)</strong></small></div> : null }

                    { rate.policy ?
                      (rate.policy.is_non_refundable ? <div className="text-green policy"><strong>Non-Refundable</strong></div> :
                        <div className="policy"><div className="text-green"><strong>{ rate.policy.name }</strong></div><p>Before { policyCancellationDate } (Admin Fee S$ { rate.policy.penalty })</p></div>)
                      : null }

                    { rate.packages ? <div className="rate-packages">{ this.loopRatePackages(rate.packages) }</div> : null }

                    <div className="description mt-2 hide-mobile">{ description }</div>


                  </div>
                </div>
                <div className="col md-5">
                  <div className="price">
                    <p className="mb-0 hide-mobile">From</p>
                    { rate.discountPercentage > 0 ?
                    <p>
                      <strong className="hide-mobile">
                        <strike className="price_before">S$ { rate.baseRate }</strike>
                        &nbsp;
                        <span className="text-danger discount">{ this.showPercentage(rate.discountPercentage) }%Off</span>
                      </strong>
                    </p> : null }
                    <div className="total_price hide-mobile">
                      S$ { rate.rate }
                    </div>
                    <div className="book-container">
                      { rate.discountPercentage > 0 ? <p className="book_now">Book now & save { parseInt(rate.discountPercentage) }%</p> : null }
                      <p className="guests">Cost for 1 night for { this.state.totalAdult + this.state.totalChildren } Guests</p>
                      <button onClick={(e)=>this.addReservation(roomCode, rateCode, specialRequests, roomName)} className="btn btn-sm btn-success btn-square book">
                        { rate.discountPercentage > 0 ? <strike className="price_before show-mobile">S$ { rate.baseRate }</strike> : null }
                        <div className="total_price show-mobile">S$ { rate.rate }</div>BOOK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
        }

        return dataToReturn;
    }

      loopRoomData(rateCode, rooms) {
          let dataToReturn = [];
          let roomData = null;

          for ( let roomCode in rooms ) {
              roomData = rooms[roomCode];

              // Prepare adult children and non-smoking icon
      				let iconAdultChildren = '';
      				let iconAdultChildrenName = '';
      				let iconNonSmoking = '';
      				let iconNonSmokingName = '';
      				for ( let key in roomData.features ) {
      						if ( roomData.features[key].room_feature_name.toLowerCase().indexOf("adults") > -1 ) {
      								iconAdultChildren = roomData.features[key].room_feature_icon;
      								iconAdultChildrenName = roomData.features[key].room_feature_name;
      						}
      						else if ( roomData.features[key].room_feature_name.toLowerCase().indexOf("smoking") > -1 ) {
      								iconNonSmoking = roomData.features[key].room_feature_icon;
      								iconNonSmokingName = roomData.features[key].room_feature_name;
      						}
      				}

              dataToReturn.push(
                  <div key={roomCode} className="row detail">
                      <div className="col-md-7">
                          <div className="plan">
                              <h5 className="text-primary mb-0"><strong>{ roomData.name }</strong>
                                  <div className="d-inline-block text-grey show-mobile plan-info">
                                    <FontAwesomeIcon icon="info-circle"/>
                                  </div>
                              </h5>
                              { roomData.isMemberOnly ? <div className="options"><small className="text-primary"><strong>(Member Only)</strong></small></div> : null }

                              {
                    							isMobileView() ? null :
                    							<div style={{ paddingTop: '15px', paddingBottom: '15px', paddingRight:15 }}>
                    								<ImgWithTooltip containerStyle={{display:'inline-block'}} imgSrc={require('../../assets/img/icon/amenity-sub-27df0737a71dd6b3eb1bcff0100d1ae25.png')} keys={roomCode} tooltipTitle='Room Size'/>
                    								<span style={{marginLeft:10}}>
                    									{ roomData.roomSize }m<sup>2</sup>
                    								</span>

                    								{ iconAdultChildren != "" ?

                    								<span style={{marginLeft:10}}>
                    									|
                    									<span style={{marginLeft:10}}>
                    											<ImgWithTooltip containerStyle={{display:'inline-block'}} imgSrc={iconAdultChildren} keys={roomCode} tooltipTitle={ iconAdultChildrenName } />
                    									</span>
                    								</span>
                    									: null }

                    								{ iconNonSmoking != "" ?

                    								<span style={{marginLeft:10}}>
                    										|
                    										<span style={{marginLeft:10}}>
                    												<ImgWithTooltip containerStyle={{display:'inline-block'}} imgSrc={iconNonSmoking} keys={roomCode} tooltipTitle={ iconNonSmokingName } />
                    										</span>
                    								</span>
                    									: null}
                    							</div>
                  						}

                              <div className="room-description mt-2 mb-2">
                                  { roomData.description }
                              </div>

                              <div className="facility-row">
                                  { this.loopRoomFeatures(roomData.features) }
                              </div>
                          </div>
                      </div>
                      <div className="col md-5">
                          <div className="price">
                              <p className="mb-0 hide-mobile">From</p>
                              { roomData.discountPercentage > 0 ?
                              <p>
                                  <strong className="hide-mobile">
                                    <strike className="price_before">S$ { roomData.baseRate[roomCode] }</strike> <span className="text-danger discount">{ parseInt(roomData.discountPercentage) }%Off</span>
                                  </strong>
                              </p> : null }
                              <div className="total_price mb-0 font-size26 hide-mobile">S$ { roomData.rate }</div>
                              <p className="text-right">Cost for { this.state.totalNight } night for { this.state.totalAdult + this.state.totalChildren } guest{ (this.state.totalAdult + this.state.totalChildren) > 1 ? "s" : "" }</p>
                              { roomData.unit < config.ROOMS_LEFT_LIMIT ? <div className="rooms text-right">{ roomData.unit } Rooms left</div> : null }
                              <div className="book-container">
                                  <button onClick={(e)=>this.addReservation(roomCode, rateCode, roomData.specialRequests, roomData.name)} className="btn btn-sm btn-success btn-square book btn-square-rates">BOOK</button>
                              </div>
                          </div>
                      </div>
                  </div>
              );
          }

          return dataToReturn;
      }

      loopRoomFeatures = (roomFeatures) => {
        let dataToReturn = [];

        for ( let roomFeatureId in roomFeatures ) {
          let roomFeature = roomFeatures[roomFeatureId];

          // Exclude adult children and no smoking icon
          if ( roomFeature.room_feature_name.toLowerCase().indexOf("smoking") === -1 && roomFeature.room_feature_name.toLowerCase().indexOf("adults") === -1 ) {
              dataToReturn.push(
                <span key={roomFeatureId} className="facility-item">
                    <ImgWithTooltip imgSrc={roomFeature.room_feature_icon} key={roomFeatureId} tooltipTitle={roomFeature.room_feature_name}/>
                </span>);
            }
        }

        return dataToReturn;
      }

    loopContentData = () => {
      if ( !this.state.rates ) return null;

      let limit = this.state.isShowAll ? this.state.totalAPIRates : config.FIRST_LOAD_TOTAL_ROOMS;
      let count = 1;
      let dataToReturn = [];

      for ( let rateCode in this.state.rates ) {
          let rateData = this.state.rates[rateCode];
          let descriptionCount = 1;

          let description = rateData.description.split('\n').map(i => {
              return <p key={descriptionCount++}>{i}</p>;
          });

          let target = count;

          let lowestRate = rateData.lowest_rate.split(".");

          // set images for the room
          let mainDisplay = '';
          let mainDisplayTitle = '';

          let images = []; let imageCaptions = [];

          for ( let galleryId in rateData.galleries ) {
              images.push(rateData.galleries[galleryId].url);
              imageCaptions.push(rateData.galleries[galleryId].title);

              if ( mainDisplay === '' ) {
                  mainDisplay = rateData.galleries[galleryId].url;
                  mainDisplayTitle = rateData.galleries[galleryId].title;
              }
          }

          let policyCancellationDate = '';
          if ( rateData.policy ) {
              if ( rateData.policy.cancellation_days > 0 ) {
                  policyCancellationDate = moment(this.state.checkInDate).add(-1 * rateData.policy.cancellation_days, 'days').format('ddd, DD MMM');
              }
          }

          dataToReturn.push(
            <div key={rateCode} className="prop room-list-details">
                <h2>{ rateData.name }</h2>
				{
					this.state['image'+target] ?
					<LightboxComponent images={ images } imageCaptions={ imageCaptions } closeLigthbox={()=>this.setState({['image'+target]:false})}/> :
					null
				}
                <div className="row">
                    <div className="col-md-4">
                        <img onClick={()=>this.setState({['image'+target]:true})} alt={mainDisplayTitle} title={mainDisplayTitle} className="img-fluid room-list-image" style={{cursor:'pointer'}} src={mainDisplay}/>

						<div className='row'>
							<div onClick={()=>this.openDetail(`data-isOpen-${target}`)} className={`col-6 col-md-12 price-container${this.state[`data-isOpen-${target}`]?' hasOpen':''}`}>
								<div>
									<h6 className={`${this.state[`data-isOpen-${target}`]?'hasOpen':''}`}>From</h6>
									<h3 className={`start-from ${this.state[`data-isOpen-${target}`]?'hasOpen':''}`}>S$ {lowestRate[0]}.<small>{lowestRate[1]}</small></h3>
							</div>
						</div>
                        {/*<div className="room-discount hide-mobile">30% Off</div>*/}

							<div className='col-6 col-md-12 order-first order-lg-2'>
								{ rateData.policy ?
								  (rateData.policy.is_non_refundable ? <div className="text-green policy"><strong>Non-Refundable</strong></div> :
									<div className="policy"><div className="text-green"><strong>{ rateData.policy.name }</strong></div><p>Before { policyCancellationDate } (Admin Fee S$ { rateData.policy.penalty })</p></div>)
								  : null }

								{ rateData.packages ? <div className="rate-packages">{ this.loopRatePackages(rateData.packages) }</div> : null }

								<div className="room-desc mt-2">
									{description}
								</div>
							</div>
						</div>
                    </div>
                    <div className={`byrates-details-mobile col-md-8 data-isOpen-${target}`}>
                        <div className="">
                            <div className="row-detail"></div>
                            { this.loopRoomData(rateCode, rateData.roomTypes) }
                            {/*<div className="row detail">
                                <div className="col-md-7">
                                    <div className="plan">
                                        <h5 className="text-primary mb-0"><strong>Mangrove Room</strong>
                                            <div className="d-inline-block text-grey show-mobile plan-info">
                                              <FontAwesomeIcon icon="info-circle"/>
                                            </div>
                                        </h5>
                                        <div className="options">
                                            <small className="text-primary"><strong>(Member Only)</strong></small>
                                            <span className="text-grey badge-room-title badge badge-warning">
                                              <FontAwesomeIcon icon="bolt"/> Last minute
                                            </span>
                                        </div>
                                        <div className="facility-row">
                                            <ImgWithTooltip containerClassName="" tooltipTitle='Coffee' imgSrc={require('../../assets/img/icon/complimentarycoffee.png')} keys='contoh'/>
                                            <ImgWithTooltip containerClassName="" tooltipTitle='TV' imgSrc={require('../../assets/img/icon/ledtv1.png')} keys='contoh'/>
                                            <ImgWithTooltip containerClassName="" tooltipTitle='Age Friendly' imgSrc={require('../../assets/img/icon/agefriendly.png')} keys='contoh'/>
                                            <ImgWithTooltip containerClassName="" tooltipTitle='Hairdryer' imgSrc={require('../../assets/img/icon/hairdryer.png')} keys='contoh'/>
                                            <ImgWithTooltip containerClassName="" tooltipTitle='Unknown' imgSrc={require('../../assets/img/icon/amenity-sub-27df0737a71dd6b3eb1bcff0100d1ae25.png')} keys='contoh'/>
                                            <ImgWithTooltip containerClassName="" tooltipTitle='Bed' imgSrc={require('../../assets/img/icon/bed.png')} keys='contoh'/>
                                            <ImgWithTooltip containerClassName="" tooltipTitle='Wifi' imgSrc={require('../../assets/img/icon/wifi.png')} keys='contoh'/>
                                            <ImgWithTooltip containerClassName="" tooltipTitle='Fridge' imgSrc={require('../../assets/img/icon/fridge.png')} keys='contoh'/>
                                            <ImgWithTooltip containerClassName="" tooltipTitle='4 Adults' imgSrc={require('../../assets/img/icon/adult4.png')} keys='contoh'/>
                                            <ImgWithTooltip containerClassName="" tooltipTitle='2 Adults 2 Children' imgSrc={require('../../assets/img/icon/adult2child2.png')} keys='contoh'/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col md-5">

                                    <div className="price">
                                        <p className="mb-0 hide-mobile">From</p>
                                        <p>
                                            <strong className="hide-mobile">
                                              <strike className="price_before">$ 162.00</strike> <span className="text-danger discount">30%Off</span>
                                            </strong>
                                        </p>
                                        <div className="total_price mb-0 font-size26 hide-mobile">$ 133.00</div>
                                        <p className="text-right">Cost for 1 night for 4 guests</p>
                                        <div className="rooms text-right">3 Rooms left</div>
                                        <div className="book-container">
                                            <button onClick={(e)=>this.addReservation('Mangrove Room',e)} className="btn btn-sm btn-success btn-square book">BOOK</button>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
          );

          if ( ++count > limit ) break;
        }

        /*dataToReturn.push(
          <div key={target} className="prop room-list-details" data-prop-id={target}>
          <h2 prop-name={target}>{ roomData.name }</h2>
          {
            this.state['image'+target] ?
            <LightboxComponent images={ images } imageCaptions={ imageCaptions } closeLigthbox={()=>this.setState({['image'+target]:false})}/> :
            null
          }
          <div className="row">
            <div className="col-md-4">
            <img onClick={()=>this.setState({['image'+target]:true})} alt={mainDisplayTitle} title={mainDisplayTitle} className="img-fluid room-list-image" style={{cursor:'pointer'}} src={mainDisplay}/>
              <div className='row'>
              {
                isMobileView() ? null :
                <div style={{ paddingTop: '5px', paddingLeft:15, paddingRight:15 }}>
                  <ImgWithTooltip containerStyle={{display:'inline-block'}} imgSrc={require('../../assets/img/icon/amenity-sub-27df0737a71dd6b3eb1bcff0100d1ae25.png')} keys={target} tooltipTitle='Room Size'/>
                  <span style={{marginLeft:10}}>
                    { roomData.roomSize }m<sup>2</sup>
                  </span>

                  { iconAdultChildren != "" ?

                  <span style={{marginLeft:10}}>
                    |
                    <span style={{marginLeft:10}}>
                        <ImgWithTooltip containerStyle={{display:'inline-block'}} imgSrc={iconAdultChildren} keys={target} tooltipTitle={ iconAdultChildrenName } />
                    </span>
                  </span>
                    : null }

                  { iconNonSmoking != "" ?

                  <span style={{marginLeft:10}}>
                      |
                      <span style={{marginLeft:10}}>
                          <ImgWithTooltip containerStyle={{display:'inline-block'}} imgSrc={iconNonSmoking} keys={target} tooltipTitle={ iconNonSmokingName } />
                      </span>
                  </span>
                    : null}
                </div>
              }
                <div onClick={()=>this.openDetail(`data-isOpen-${target}`)} className={`col-6 col-md-12 price-container${this.state[`data-isOpen-${target}`]?' hasOpen':''}`}>
                  <div>
                    <h6>From</h6>
                    <h3 className={`start-from${this.state[`data-isOpen-${target}`]?' hasOpen':''}`}>S$ {lowestRate[0]}.<small>{lowestRate[1]}</small></h3>
                    { roomData.unit < config.ROOMS_LEFT_LIMIT ? <div className="rooms">{ roomData.unit } Rooms left</div> : null }
                  </div>
                </div>
                <div className='col-6 col-md-12 order-first order-lg-2'>
                  {
                    isMobileView() ?
                    (
                      <div style={{ paddingTop: '5px',paddingBottom:10, paddingLeft:15, paddingRight:15 }}>
                        <ImgWithTooltip containerStyle={{display:'inline-block'}} imgSrc={require('../../assets/img/icon/amenity-sub-27df0737a71dd6b3eb1bcff0100d1ae25.png')} keys={target} tooltipTitle='Room Size'/>
                        <span style={{marginLeft:10}}>
                          { roomData.roomSize }m<sup>2</sup>
                        </span>

                        { iconAdultChildren != "" ?

                        <span style={{marginLeft:10}}>
                          |
                          <span style={{marginLeft:10}}>
                              <ImgWithTooltip containerStyle={{display:'inline-block'}} imgSrc={iconAdultChildren} keys={target} tooltipTitle={ iconAdultChildrenName } />
                          </span>
                        </span>
                          : null }

                        { iconNonSmoking != "" ?

                        <span style={{marginLeft:10}}>
                            |
                            <span style={{marginLeft:10}}>
                                <ImgWithTooltip containerStyle={{display:'inline-block'}} imgSrc={iconNonSmoking} keys={target} tooltipTitle={ iconNonSmokingName } />
                            </span>
                        </span>
                          : null}
                      </div>
                    ) : null
                  }
                  <div className="facility-item-container">
                    <table style={{maxWidth:'100%'}}>
                      {this.loopRoomFeatures(roomData.features)}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-md-8 data-isOpen-${target}`}>
            <div className="">
              <div className="row d-none d-md-flex">
              <div className="col-md-7">
                <p><strong>Rate Plan</strong></p>
              </div>
              <div className="col-md-5">
                <p className="text-right"><strong>Average per Night</strong></p>
              </div>
              </div>
              { this.loopRateData(roomCode, roomData.rates, roomData.specialRequests, roomData.name) }
            </div>
            </div>
          </div>
          </div>
        );

        if ( ++count > limit ) break;
      }*/
      return dataToReturn;
    }

      componentWillReceiveProps(nextProps) {
          if ( ( this.state.isSearch && nextProps.rates ) || ( nextProps.rooms && !this.state.rates ) ) {
              if ( nextProps.rates != "[]" ) {
                  let totalRates = 0;
                  for ( let roomCode in nextProps.rates ) {
                      totalRates++;
                  }

                  this.setState({ rates: nextProps.rates, isSearch: false, isShowAll: false, totalAPIRates: totalRates });

                  console.log(totalRates + " " + config.FIRST_LOAD_TOTAL_RATES);

                  if ( totalRates > config.FIRST_LOAD_TOTAL_RATES ) {
                      $("#show_more").show();
                  }
                  else {
                      $("#show_more").hide();
                  }
              }
              else {
                  this.setState({ rates: null, isSearch: false, isShowAll: false, totalAPIRates: 0 });
              }
          }

          if ( this.state.isProcessBooking && nextProps.roomRateDetail ) {
              if ( nextProps.roomRateDetail.totalRoomRateAndPackages ) {
                  localStorage.setItem(config.KEY_ROOM_NAME, this.state.roomName);
                  localStorage.setItem(config.KEY_ROOM_CODE, this.state.roomCode);
                  localStorage.setItem(config.KEY_RATE_CODE, this.state.ratePlanCode);
                  localStorage.setItem(config.KEY_CHECK_IN_DATE, this.state.checkInDate);
                  localStorage.setItem(config.KEY_CHECK_OUT_DATE, this.state.checkOutDate);
                  localStorage.setItem(config.KEY_TOTAL_NIGHT, this.state.totalNight);
                  localStorage.setItem(config.KEY_TOTAL_ROOMS, this.state.totalRooms);
                  localStorage.setItem(config.KEY_TOTAL_ADULT, this.state.totalAdult);
                  localStorage.setItem(config.KEY_TOTAL_CHILDREN, this.state.totalChildren);
                  localStorage.setItem(config.KEY_SPECIAL_CODE_TYPE, this.state.specialCodeType);
                  localStorage.setItem(config.KEY_SPECIAL_CODE, this.state.specialCode);
                  localStorage.setItem(config.KEY_SPECIAL_REQUESTS, JSON.stringify(this.state.specialRequests));

                  let roomRateDetail = nextProps.roomRateDetail;

                  localStorage.setItem(config.KEY_TOTAL_ROOM_RATE_AND_PACKAGES, roomRateDetail.totalRoomRateAndPackages);
                  localStorage.setItem(config.KEY_TOTAL_TAXES_AND_FEES, roomRateDetail.totalTaxesAndFees);
                  localStorage.setItem(config.KEY_TAX_INCLUSIVE, roomRateDetail.taxInclusive);
                  localStorage.setItem(config.KEY_GRAND_TOTAL, roomRateDetail.grandTotal);

                  this.props.history.push('/addons');
              }
              else {
                  this.setState({ isProcessBooking: false });
              }
          }
      }
    reFormatRoomData = () => {
      let target = $('.room-title');
      target.each((index,element)=>{
        element.innerHTML = `Room ${index+1}`;
      });
      let deleteBtn = $('.room-delete-btn');
      if(deleteBtn.length===1){
        deleteBtn.each((index,val)=>{
          val.hidden = true;
        })
      }else{
        deleteBtn.each((index,val)=>{
          val.hidden = false;
        })
      }
    }
    reFormatRoomData = () => {
      let target = $('.room-title');
      target.each((index,element)=>{
        element.innerHTML = `Room ${index+1}`;
      });
      let deleteBtn = $('.room-delete-btn');
      if(deleteBtn.length===1){
        deleteBtn.each((index,val)=>{
          val.hidden = true;
        })
      }else{
        deleteBtn.each((index,val)=>{
          val.hidden = false;
        })
      }
    }

    showPercentage(percentage) {
  			let roundDown = Math.floor(percentage);
  			let decimal = percentage - roundDown;

  			return decimal > 0.49999999999 ? roundDown + 1 : roundDown;
  	}
    checkingClearButtonRequire = () => {
      if(this.state.specialCodeType || this.state.specialCode){
        return true
      }else{
        return false
      }
    }
    render(){
      this.reFormatRoomData();
        return(
            <div>
                { ( this.state.isSearch || this.state.isProcessBooking ) ? _loading() : ""}

                <section className="banner-slider wrap">
      						<div className="banner-slider-inner">
      							<div className="jumbotron jumbotron-fluid">
      								<div className="jumbotron-background" style={{backgroundImage:`url(${BackgroundImage})`}}>
      								</div>
      							</div>
      							<div className="static">
      								<img src={MyAssets.img.bannerTwo} alt="" style={{height:'auto',width:'100%',marginLeft:'0px'}}/>
      							</div>
      						</div>
      						<div id="header-filter">
      							<div className="container">
      								<div className="row">

      									<div className="col-6 col-md-2">
      										<span className="input-group input-group-light-prepend">
      										<div className="input-group-prepend hide-xs-prepend">
      											<button className="btn " type="button">
      											<i className="icon light-calendar"></i>
      											</button>
      										</div>
      										<DateRangePicker locale={{format:'DD MMM YYYY'}} startDate={this.state.startDateRangePickerValue} endDate={this.state.endDateRangePickerValue} minDate={DATE_NOW_STRING} onApply={(event,picker)=>this.handleDateRangePickerApply(event,picker)} containerStyles={{position:'absolute',width:'100%'}}>
      										<input value={this.state.startDateString} onChange={()=>{}} type="text" style={{height:'calc( 1.8em + 1.5em + 2px',fontWeight:'600',fontSize:'0.9em',border:'none',borderRadius:0,paddingLeft:isMobileView()?'6px':'50px'}} className="form-control date-picker" id="date-start" placeholder="Check In Date"
      											autoComplete="off"/>
      										</DateRangePicker>
      										</span>
      									</div>

      									<div id="count_days"> <span>{this.state.totalNight} Night{this.state.totalNight>1?'s':''}</span> </div>

      									<div className="col-6 col-md-2">
      										<span className="input-group input-group-light-prepend">
      										<DateRangePicker locale={{format:'DD MMM YYYY'}} startDate={this.state.startDateRangePickerValue} endDate={this.state.endDateRangePickerValue} minDate={DATE_NOW_STRING} onApply={(event,picker)=>this.handleDateRangePickerApply(event,picker)} containerStyles={{width:'100%'}}>
      										<input value={this.state.endDateString} onChange={()=>{}} type="text" style={{height:'calc( 1.8em + 1.5em + 2px',fontWeight:'600',fontSize:'0.9em',border:'none',borderRadius:0,paddingLeft:'40px'}} className="form-control date-picker" id="date-end" placeholder="Check Out Date"
      											autoComplete="off"/>
      										</DateRangePicker>
      										</span>
      									</div>

      									<div id='person-input-container' className="col-12 col-md-3">
      										<span className="input-group input-group-light-prepend ">
      											<div className="input-group-prepend hide-xs-prepend">
      												<button className="btn " type="button">
      													<i className="icon light-people"></i>
      												</button>
      											</div>
      											<input onFocus={()=>this.showHideCountPerson(true)} type="text" className="form-control count-person-pop-up" id="persons" value={this.state.persons} onChange={(e)=>this.setState({persons:e.target.value})} autoComplete="off"/>
      										</span>
      										<div className="count-person-pop-up" style={this.countPersonStyle()} id="count_person">
      											<span className="person_title count-person-pop-up">Max. {config.MAX_ADULT_CHILDREN} guests</span>
      											<div className="container-grey count-person-pop-up">
      												<div className="container count-person-pop-up">
      													<div className="row count-person-pop-up">
      														<div className="col-4 col-lg-4 count-person-pop-up">Room(s)</div>
      														<div className="col-4 col-lg-4 count-person-pop-up">Adult(s)</div>
      														<div className="col-4 col-lg-4 count-person-pop-up">Children (under 12)</div>
      														{/* <div className='col-1 col-lg-1 count-person-pop-up'></div> */}
      													</div>
      												</div>
      											</div>
												<div className="count-person-pop-up">
												<div className="container count-person-pop-up" id="row-room">
													<div  id={`input-spin-1`} className="row input-spin count-person-pop-up">
														<div className="col-4 col-lg-4 count-person-pop-up">
															<div className="input-group input-group-sm count-person-pop-up">
																<div className="input-group-prepend count-person-pop-up">
																	<button onClick={()=>this.handleRoomChanged(COUNT_PERSON.MIN, '1')} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
																</div>
																<input onChange={()=>{}} id="input-room-1" defaultValue={this.state.totalRooms} className="form-control input-room count-person-pop-up" type="text" style={{textAlign:'center'}}/>
																<div className="input-group-append count-person-pop-up">
																	<button onClick={()=>this.handleRoomChanged(COUNT_PERSON.PLUS, '1')} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
																</div>
															</div>

														</div>
														<div className="col-4 col-lg-4 count-person-pop-up">
															<div className="input-group input-group-sm count-person-pop-up">
																<div className="input-group-prepend count-person-pop-up">
																	<button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-adults-1`,'input-children-1')} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
																</div>
																<input onChange={()=>{}} id="input-adults-1" defaultValue={this.state.totalAdult} className="form-control input-adults count-person-pop-up" type="text" style={{textAlign:'center'}}/>
																<div className="input-group-append count-person-pop-up">
																	<button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-adults-1`,'input-children-1')} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
																</div>
															</div>
														</div>
														<div className="col-4 col-lg-4 count-person-pop-up">
															<div className="input-group input-group-sm count-person-pop-up">
																<div className="input-group-prepend count-person-pop-up">
																	<button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-children-1`,'input-adults-1')} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
																</div>
																<input onChange={()=>{}} id='input-children-1' defaultValue={this.state.totalChildren} placeholder='0' className="form-control input-children count-person-pop-up" type="text" style={{textAlign:'center'}}/>
																<div className="input-group-append count-person-pop-up">
																	<button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-children-1`,'input-adults-1')} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
																</div>
															</div>
														</div>
														{/* <div className='col-1 room-delete-btn col-lg-1 count-person-pop-up'>
															<div onClick={()=>this.handleDeleteRoom('input-spin-1')} className='count-person-pop-up' style={{width:20,height:20,borderRadius:10,borderColor: 'red',fontWeight:'bold',color:'red',marginTop:8,position:'relative',left:-4,cursor:'pointer'}}>X</div>
														</div> */}
													</div>
												</div>
											</div>
      											{/* <div className="count-person-pop-up">
      												<div className="container count-person-pop-up" id="row-room">
      													<div  id={`input-spin-1`} className="row input-spin count-person-pop-up">
      														<div className="col-3 col-lg-3 count-person-pop-up">
      															<span className="room count-person-pop-up room-title">Room 1</span>
      														</div>
      														<div className="col-4 col-lg-4 count-person-pop-up">
      															<div className="input-group input-group-sm count-person-pop-up">
      																<div className="input-group-prepend count-person-pop-up">
      																	<button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-adults-1`,'input-children-1')} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
      																</div>
      																<input onChange={()=>{}} id="input-adults-1" defaultValue="1" className="form-control input-adults count-person-pop-up" type="text" style={{textAlign:'center'}}/>
      																<div className="input-group-append count-person-pop-up">
      																	<button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-adults-1`,'input-children-1')} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
      																</div>
      															</div>
      														</div>
      														<div className="col-4 col-lg-4 count-person-pop-up">
      															<div className="input-group input-group-sm count-person-pop-up">
      																<div className="input-group-prepend count-person-pop-up">
      																	<button onClick={()=>this.handleCountPerson(COUNT_PERSON.MIN,`input-children-1`,'input-adults-1')} style={{minWidth:'2rem'}} className="btn btn-decrement count-person-pop-up" type="button"><strong className="count-person-pop-up">-</strong></button>
      																</div>
      																<input onChange={()=>{}} id='input-children-1' defaultValue="" placeholder='0' className="form-control input-children count-person-pop-up" type="text" style={{textAlign:'center'}}/>
      																<div className="input-group-append count-person-pop-up">
      																	<button onClick={()=>this.handleCountPerson(COUNT_PERSON.PLUS,`input-children-1`,'input-adults-1')} style={{minWidth:'2rem'}} className="btn btn-increment count-person-pop-up" type="button"><strong className="count-person-pop-up">+</strong></button>
      																</div>
      															</div>
      														</div>
      														<div className='col-1 room-delete-btn col-lg-1 count-person-pop-up'>
      															<div onClick={()=>this.handleDeleteRoom('input-spin-1')} className='count-person-pop-up' style={{width:20,height:20,borderRadius:10,borderColor: 'red',fontWeight:'bold',color:'red',marginTop:8,position:'relative',left:-4,cursor:'pointer'}}>X</div>
      														</div>
      													</div>
      												</div>
      											</div> */}
      											{
      												/* this.checkCurrentRooms() === 5 ? null : (
      													<div className='count-person-pop-up'>
      														<div className="container count-person-pop-up" id="add-more-room">
      															<button onClick={this.addNewRoom} className="btn-square btn btn-sm btn-success count-person-pop-up">ADD ROOM</button>
      														</div>
      													</div>
      												) */
      											}
      											{
      												/* this.checkCurrentChildrenCount() ?
      												<div className="count-person-pop-up">
      													<div style={{textAlign:'left',paddingRight:15,paddingLeft:15}} className='container count-person-pop-up'>
      														<div className="count-person-pop-up" style={{backgroundColor:'#efefef',paddingLeft:15,paddingRight:15,paddingTop:10,paddingBottom:10,marginBottom:15}}>
      															Children must be under 12 years for age.<br/>
      															Special children's meal plan available at our hotels.
      														</div>
      													</div>
      												</div> : null */
      											}
      										</div>
      									</div>

      									<div className="col-12 col-sm-3">
      										<span className="input-group input-group-light-prepend" id="special_code_wrapper">
      										<div className="input-group-prepend">
      											<button className="btn " type="button">
      											<i className="icon light-tag"></i>
      											</button>
      										</div>
      										<input onFocus={()=>this.showHideSpesialCode(true)} onChange={()=>{}} value={ this.state.specialCodeText } type="text" className="form-control spesial-code-pop-up" placeholder="Special Code" autoComplete="off"/>
      										</span>
      										<div className="spesial-code-pop-up" style={{position:'absolute',width:300,background:'white'}} id="popup-specialcode">
      											<div className="container spesial-code-pop-up">
      												<p className='spesial-code-pop-up'>Corporate/Special Rate (Optional)</p>
      												<div className='spesial-code-pop-up'>
      													<select value={ this.state.specialCodeType } className="custom-select form-sm spesial-code-pop-up" onChange={ this.handleSpecialCodeType.bind(this) }>
      														<option val="">Select Code Type</option>
      														<option val="promo">Promo Code</option>
      														<option val="corporate">Corporate Code</option>
      													</select>
      												</div>
      												<div className='spesial-code-pop-up'>
      													<input type="text" value={ this.state.specialCode } className="form-control custom-select form-sm spesial-code-pop-up" placeholder="Enter Code"
      														onChange={ this.handleSpecialCode.bind(this) } />
      												</div>
                              {
                                this.checkingClearButtonRequire() ?
                                <div className='spesial-code-pop-up'>
                                  <button onClick={()=>{
                                    this.setState({specialCode:'',specialCodeText:'',specialCodeType:''})
                                  }} style={{marginBottom:20}} className="spesial-code-pop-up btn btn-sm btn-success btn-square">Clear</button>
                                </div> : null
                              }
      											</div>
      										</div>
      									</div>

      									<div className="col-12 col-sm-2">
      										<button className="btn btn-xl btn-success btn-square" onClick={ this.searchRate.bind(this) }>SEARCH</button>
      									</div>

      								</div>
      							</div>
      						</div>
      					</section>

                <div id="book-step">
      						<div className="container">
      							<div className="row">
      								<div className="step active" data-id="1">
      									<span style={{paddingTop:2}} className="number-count">1</span>Select Room & Dates
      								</div>

      								<div className="step next" data-id="2">
      									<span style={{paddingTop:2}} className="number-count">2</span>Select Add-Ons
      								</div>

      								<div className="step next" data-id="3">
      									<span style={{paddingTop:1}} className="number-count">3</span>Guest Information & Payment
      								</div>
      								<div className="step next" data-id="4">
      									<span style={{paddingTop:1}} className="number-count">4</span>Confirmation
      								</div>
      								<div className="vertical-line">
      								</div>
      								<div id="sort">
      									<div className="btn-group position-relative" id="sort-list">
      										<button type="button" onClick={this.toggleDropDown} className="btn btn-none dropdown-toggle">Views By: <span id="sort-by">Rates</span></button>
      										{/*<button type="button" onClick={this.toggleDropDown} className="btn btn-none dropdown-toggle">
      										<span className="sr-only">Toggle Dropdown</span>
      										</button>*/}
      										<div className="dropdown-menu" id="sort-dropdown">
      											<NavLink exact className="dropdown-item" to="/">Rooms</NavLink>
      											<NavLink exact className="dropdown-item" to="/byrates">Rates</NavLink>
      										</div>
      									</div>
      								</div>
      							</div>
      						</div>
      					</div>

                {isMobileView() ?
                (
                        <div className="container" id="textStep">
                                <div className="row">
                                        <p>Select Room & Dates</p>
                                </div>
                                <div id="clonedSort">
                                  <div className="btn-group position-relative" id="sort-list">
                                    <button type="button" onClick={this.toggleDropDown} className="btn btn-none dropdown-toggle">Views By: <span id="sort-by">Rooms</span></button>
                                    <div className="dropdown-menu" id="sort-dropdown">
                                      <NavLink exact className="dropdown-item" to="/">Rooms</NavLink>
                                      <NavLink exact className="dropdown-item" to="/byrates">Rates</NavLink>
                                    </div>
                                  </div>
                                </div>
                        </div>
                ) :
                null
                }
                <section id="total-reservation" className="show-mobile">
                    <div className="container">
                        <div className="row reservation">
                            <div className="col-6 left">
                                <p className="grand-total"><span className="count">3x</span> | Grand Total: <span className="price">S$703.20</span></p>
                                <p className="link" data-toggle="modal" data-target="#reservation-modal">View/Modify</p>
                            </div>
                            <div className="col-6 right">
                                <p>Make this reservation</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div id="main">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12" id="lists">
                                { this.loopContentData() }
                            </div>
                        </div>
                    </div>
                    <div id="show_more">
                        <button className="btn btn-success btn-square" onClick={ this.setShowAll.bind(this) }>SHOW MORE</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let params = { rates: [], roomRateDetail: '' }

    if ( state.rooms.result ) {
        params.rates = state.rooms.result;
    }

    if ( state.roomRate.result ) {
        params.roomRateDetail = state.roomRate.result;
    }

    return params;
}

const mapDispatchToProps = (dispatch) => {
  const { searchRates, roomRateDetails } = allAPI;
  return bindActionCreators({ searchRates, roomRateDetails }, dispatch);
}

export default (connect(mapStateToProps, mapDispatchToProps)(Rate));
