// - Homepage
export const API_SEARCH_ROOM = 'searchRooms';
export const API_SEARCH_RATES = 'searchRates';
export const API_ROOM_RATE_DETAIL = 'roomRateDetails';

// - Book
export const API_BOOK_ROOM = 'bookRoom';

// - Payment
export const API_PAYMENT = 'payment';

// - Login
export const API_LOGIN = 'login';
export const API_LOGOUT = 'logout';

// - Booking Detail
export const API_BOOKING_DETAIL = 'bookingDetail';
export const API_RETRIEVE_BOOKING = 'retrieveBooking';
export const API_RETRIEVE_BOOKING_WITH_TOKEN = 'retrieveBookingWithToken';
export const API_RETRIEVE_BOOKING_FOR_ADDON = 'retrieveBookingForAddon';

// - Get Website's content
export const API_GET_CONTENT = 'getContent';

// - Addon - get addon price
export const API_GET_ADDON_PRICE = 'getAddonPrice';

// Cancel Booking
export const API_CANCEL_BOOKING = 'cancelBooking';

// Modify Booking
export const API_MODIFY_BOOKING = 'modifyBooking';

// Modify Addon
export const API_AMEND_BOOKING_ADDON = 'amendBookingAddons';

// Preregister
export const API_PREREGISTER = 'preregister';
